/* src/components/ProfileForm/ProfileForm.css */

.profile-form-container {
    position: fixed;
    inset: 0;
    background-color: var(--color-background);
    z-index: 1000;
  }
  
  .profile-form-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: var(--color-surface);
    border-bottom: 1px solid var(--color-text-tertiary);
    z-index: 1001;
  }

  .profile-form-close-button {
    position: absolute;
    left: 1rem;
  }

  .profile-form-submit {
    position: absolute;
    right: 1rem;
  }
  
  .profile-form-close-button {
    padding: 0.5rem;
    border-radius: 50%;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .profile-form-close-button:hover {
    background-color: var(--color-hover);
  }
  
  .profile-form-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--color-text-primary);
    margin-bottom: 0;
  }
  
  .profile-form-submit {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 9999px;
    font-weight: 600;
    font-size: 1rem;
    color: var(--color-primary);
    background: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .profile-form-submit:hover:not(:disabled) {
    background-color: var(--color-hover);
  }
  
  .profile-form-submit:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .profile-form-content {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 5rem;
    padding-top: 4rem;
  }
  
  .profile-photo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
  }
  
  .profile-form-fields {
    padding: 0 1rem;
  }
  
  .form-field-group {
    background-color: var(--color-surface);
    border-radius: 8px;
    margin-bottom: 1rem;
    overflow: hidden;
  }
  
  .form-field {
    padding: 1rem;
  }
  
  .form-field label {
    display: block;
    font-size: 0.875rem;
    color: var(--color-text-secondary);
    margin-bottom: 0.5rem;
  }
  
  .form-field input,
  .form-field textarea {
    width: 100%;
    padding: 0.5rem;
    border: none;
    background: none;
    color: var(--color-text-primary);
    font-size: 1rem;
  }
  
  .form-field input:focus,
  .form-field textarea:focus {
    outline: none;
  }
  
  .form-error {
    color: var(--color-error);
    font-size: 0.875rem;
    padding: 0 1rem 0.5rem;
  }
  
  .form-general-error {
    margin: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: rgba(234, 67, 53, 0.1);
    color: var(--color-error);
  }
  
  .success-toast {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1002;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Mobile Optimizations */
  @media (max-width: 768px) {
    .profile-form-content {
      padding-bottom: 3rem;
    }
  
    .form-field {
      padding: 0.75rem;
    }
  }
  
  /* Animations */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .profile-form-container {
    animation: fadeIn 0.3s ease-out;
  }
  
  .success-toast {
    animation: slideUp 0.3s ease-out;
  }