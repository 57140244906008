/* src/components/ProfileHeader/SettingsMenu.css */

.settings-backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 110; /* Increased to be higher than bottom navigation */
  }
  
  .settings-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(0);
    z-index: 120; /* Increased to be higher than bottom navigation */
    transition: transform 0.3s ease-in-out;
  }
  
  .settings-container.hidden {
    transform: translateY(100%);
  }
  
  .settings-content {
    background-color: var(--color-surface);
    border-radius: 16px 16px 0 0;
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: calc(env(safe-area-inset-bottom, 16px) + 20px); /* Added padding to account for bottom navigation */
  }
  
  .settings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid var(--color-text-tertiary);
  }
  
  .settings-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color-text-primary);
    font-weight: 600;
  }
  
  .settings-close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 50%;
    border: none;
    background: none;
    cursor: pointer;
    color: var(--color-text-secondary);
    transition: background-color 0.2s;
  }
  
  .settings-close-button:hover {
    background-color: var(--color-hover);
  }
  
  .settings-body {
    padding: 0.5rem;
  }
  
  .settings-section {
    margin-bottom: 1rem;
  }
  
  .settings-section-title {
    padding: 0.5rem 1rem;
    color: var(--color-text-secondary);
    font-size: 0.875rem;
  }
  
  /* Logout button specific styles */
  .settings-button.settings-logout {
    color: var(--color-error);
  }
  
  .settings-button.settings-logout:hover {
    background-color: rgba(234, 67, 53, 0.1);
  }
  
  .settings-button.settings-logout .settings-button-icon {
    color: var(--color-error);
  }
  
  /* Desktop styles */
  @media (min-width: 768px) {
    .settings-container {
      position: absolute;
      top: 48px;
      bottom: auto;
      right: 0;
      left: auto;
      width: 320px;
    }
  
    .settings-content {
      border-radius: 12px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      padding-bottom: initial; /* Remove extra padding on desktop */
    }
  }