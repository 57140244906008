/* Import Google Font - You can change this to your preferred font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Basic Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: var(--color-background);
  color: var(--color-text-primary);
  line-height: 1.6;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  margin-bottom: 1rem;
}

ul {
  list-style: none;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

/* Form Elements */
input,
textarea,
select {
  font-family: inherit;
  font-size: 16px;
  padding: 0.8rem;
  border: 1px solid var(--color-text-tertiary);
  border-radius: 8px;
  margin-bottom: 1rem;
  width: 100%;
  background-color: var(--color-surface);
  color: var(--color-text-primary);
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-focus);
}

/* Layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  background-color: var(--color-surface);
}

main {
  flex-grow: 1;
  padding: 2rem;
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.mt-1 { margin-top: 0.5rem; }
.mt-2 { margin-top: 1rem; }
.mt-3 { margin-top: 1.5rem; }
.mt-4 { margin-top: 2rem; }

.mb-1 { margin-bottom: 0.5rem; }
.mb-2 { margin-bottom: 1rem; }
.mb-3 { margin-bottom: 1.5rem; }
.mb-4 { margin-bottom: 2rem; }

/* Responsiveness */
@media (max-width: 768px) {
  main {
    padding: 1rem;
  }

  .app-container {
    max-width: 100%;
  }
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: var(--color-text-secondary);
}

/* Error Message */
.error-message {
  color: var(--color-error);
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

/* Success Message */
.success-message {
  color: var(--color-success);
  background-color: var(--color-success-light);
  border: 1px solid var(--color-success);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

/* Additional Utility Classes */
.flex { display: flex; }
.flex-col { flex-direction: column; }
.items-center { align-items: center; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }

.w-full { width: 100%; }
.h-full { height: 100%; }

.p-1 { padding: 0.25rem; }
.p-2 { padding: 0.5rem; }
.p-3 { padding: 0.75rem; }
.p-4 { padding: 1rem; }