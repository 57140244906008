.profile-image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-image-container {
  position: relative;
  width: 96px;
  height: 96px;
  margin-bottom: 1rem;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  background-color: var(--color-background);
}

.profile-image-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-tertiary);
}

.profile-image-edit-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--color-primary);
  border: 3px solid var(--color-surface);
  color: var(--color-surface);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.profile-image-edit-button:hover {
  background-color: var(--color-primary-dark);
}

/* Crop Modal Styles */
.crop-modal {
  position: fixed;
  inset: 0;
  background-color: var(--color-surface);
  z-index: 1100;
  display: flex;
  flex-direction: column;
}

.crop-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--color-text-tertiary);
}

.crop-modal-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--color-text-primary);
}

.crop-container {
  flex: 1;
  position: relative;
  background-color: var(--color-background);
}

.crop-controls {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-surface);
  border-top: 1px solid var(--color-text-tertiary);
}

.crop-control-button {
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.crop-control-button.secondary {
  background: none;
  color: var(--color-text-secondary);
  border: none;
}

.crop-control-button.primary {
  background-color: var(--color-primary);
  color: var(--color-surface);
  border: none;
}

.crop-control-button:hover {
  opacity: 0.9;
}

/* File Input */
.file-input {
  display: none;
}

/* Animations */
@keyframes modalSlideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.crop-modal {
  animation: modalSlideUp 0.3s ease-out;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .profile-image-container {
    width: 88px;
    height: 88px;
  }
  
  .profile-image-edit-button {
    width: 28px;
    height: 28px;
  }
}