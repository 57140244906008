/* Comment Section Styles */

.commentItem {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-text-tertiary);
}

.commentContent {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.commentContent strong,
.replyContent strong {
  font-size: var(--font-size-md);
  color: var(--color-text-primary);
  font-weight: var(--font-weight-semibold);
  margin-bottom: 0.25rem;
}

.commentText,
.replyContent span {
  font-size: var(--font-size-md);
  color: var(--color-text-primary);
}

.commentText .mention,
.replyContent span .mention {
  color: #6e7c7c;
  font-weight: var(--font-weight-normal);
}

.commentMeta {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-xs);
  color: var(--color-text-secondary);
  margin-top: 0.5rem;
}

.commentTimestamp,
.replyTimestamp {
  color: var(--color-text-tertiary);
  margin-bottom: 0.5rem;
}

.commentActions {
  display: flex;
  align-items: center;
}

.replyButton,
.deleteButton,
.toggleRepliesButton {
  background: none;
  border: none;
  color: var(--color-text-secondary);
  cursor: pointer;
  font-size: calc(var(--font-size-xs) * 1.1);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-semibold);
  padding: 0;
  transition: color 0.3s ease;
  margin-right: 1rem;
}

.replyButton:hover,
.deleteButton:hover,
.toggleRepliesButton:hover {
  color: var(--color-primary);
}

.replies {
  margin-left: 1rem;
  margin-top: 1rem;
}

.reply {
  margin-bottom: 1rem;
}

.replyContent {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
}

.replyMeta {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-xs);
  color: var(--color-text-secondary);
  margin-top: 0.5rem;
}

.commentContent span,
.replyContent span {
  white-space: pre-wrap;
  word-break: break-word;
}

.replyForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 0;
  position: relative;
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.replyForm textarea {
  width: 100%;
  min-height: 36px;
  max-height: 150px;
  resize: vertical;
  margin-bottom: 0.5rem;
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  padding: 0.5rem 2.5rem 0.75rem 1rem;
  border: 1px solid var(--color-text-tertiary);
  border-radius: 20px;
  background-color: var(--color-background);
  color: var(--color-text-primary);
  transition: border-color 0.3s ease, box-shadow 0.3s ease, height 0.2s ease;
  overflow-y: auto;
}

.replyForm textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 1px var(--color-primary);
}

.sendButton {
  position: absolute;
  right: 4px;
  bottom: 18px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
}

.sendButton:hover {
  background-color: var(--color-primary-dark);
}

.sendButton:active {
  transform: scale(0.95);
}

.sendButton svg {
  width: 18px;
  height: 18px;
}

.toggleRepliesButton {
  margin-top: 0.5rem;
}

.highlight {
  background-color: var(--color-hover);
  border-left: 3px solid var(--color-primary);
  padding-left: 0.75rem;
  margin-left: -0.75rem;
  animation: highlightFade 3s ease-out;
}

@keyframes highlightFade {
  0% { background-color: var(--color-hover); }
  100% { background-color: transparent; }
}

/* Responsive Styles */
@media (min-width: 376px) {
  .commentMeta,
  .replyMeta {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .commentTimestamp,
  .replyTimestamp {
    margin-bottom: 0;
  }

  .commentActions {
    justify-content: flex-end;
  }

  .replyButton,
  .deleteButton {
    margin-left: 1rem;
    margin-right: 0;
  }
}

/* Utility classes */
.mt-1 { margin-top: 0.5rem; }
.mb-1 { margin-bottom: 0.5rem; }
.flex { display: flex; }
.items-center { align-items: center; }
.text-small { font-size: var(--font-size-sm); }