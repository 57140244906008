/* src/styles/SearchBar.css */

.search-bar {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .search-input {
    width: 100%;
    padding: 12px 20px 12px 40px; /* Adjusted left padding for icon */
    font-size: 16px;
    line-height: 1.5; /* Added for consistent text alignment */
    border: 2px solid var(--color-text-tertiary);
    border-radius: 25px;
    background-color: var(--color-surface);
    color: var(--color-text-primary);
    transition: all 0.3s ease;
    margin-bottom: 0;
  }
  
  .search-input:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px var(--color-focus);
  }
  
  .search-input::placeholder {
    color: var(--color-text-tertiary);
  }
  
  /* Search icon */
  .search-icon {
    position: absolute;
    left: 14px; /* Adjusted for better alignment */
    top: 50%;
    transform: translateY(-50%);
    width: 18px; /* Slightly reduced size */
    height: 18px;
    color: var(--color-text-tertiary);
    pointer-events: none;
  }
  
  /* Clear button */
  .search-clear {
    position: absolute;
    right: 14px; /* Adjusted for better alignment */
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--color-text-tertiary);
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px; /* Set a fixed width */
    height: 18px; /* Set a fixed height */
  }
  
  .search-clear svg {
    width: 100%;
    height: 100%;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .search-input {
      font-size: 14px;
      padding: 10px 15px 10px 36px; /* Adjusted padding */
    }
  
    .search-icon {
      width: 18px;
      height: 18px;
      left: 14px;
    }
  
    .search-clear {
      width: 18px;
      height: 18px;
      right: 14px;
    }
  }