/* src/components/PostMessage.css */

.post-message {
  margin: 0.5rem 0;
  background-color: var(--color-background);
  border-radius: 1rem;
  padding: 0.7rem 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 97%;
}

.message-container.received .post-message {
  border-top-left-radius: 4px;
}

.message-container.sent .post-message {
  border-bottom-right-radius: 4px;
}

.post-message.error {
  padding: 0.7rem 1rem;
  text-align: center;
  color: var(--color-error);
}

/* Remove duplicate styling from nested post item */
.post-message .chat-post-item {
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
}

/* Loading skeleton styles */
/* Using a more specific selector to override global .loading class */
.post-message.loading.post-message-loading {
  padding: 0.7rem 1rem;
  background-color: var(--color-surface);
  height: auto;
  display: block;
}

.loading-skeleton {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 12px;
}

.loading-skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(var(--color-primary-rgb), 0.1),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

.loading-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--color-background);
  flex-shrink: 0;
}

.loading-text {
  flex: 1;
}

.loading-name {
  height: 16px;
  width: 150px;
  background-color: var(--color-background);
  border-radius: 4px;
  margin-bottom: 4px;
}

.loading-title {
  height: 20px;
  width: 200px;
  background-color: var(--color-background);
  border-radius: 4px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .post-message {
    padding: 0.3rem 0.3rem;
    margin: 0.4rem 0;
  }
}