/* src/components/ChatPostItem.css */

.chat-post-item {
    background-color: var(--color-surface);
    border: 1px solid var(--color-text-tertiary);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }
  
  .post-header {
    padding: 0.5rem 0.75rem 0.5rem 0.35rem;
    background-color: var(--color-background);
    cursor: pointer;
  }
  
  .post-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .post-author {
    display: flex;
    align-items: flex-start;
    gap: 0.35rem;
    flex: 1;
    min-width: 0;
  }
  
  .author-avatar {
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
    max-width: none;
  }
  
  .author-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    min-width: 0;
    max-width: 85%;
  }

  .author-name {
    font-size: var(--font-size-sm);
    color: var(--color-text-secondary);
    margin: 0;
    line-height: 1.2;
    font-weight: var(--font-weight-medium);
  }
  
  .post-title {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    color: var(--color-text-primary);
    margin: 0;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .post-expand-icon {
    color: var(--color-text-secondary);
    margin-left: 0.75rem;
    padding-top: 0.25rem;
  }
  
  .post-content {
    padding: 1rem;
  }
  
  .post-description {
    color: var(--color-text-primary);
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .post-description p {
    margin-bottom: 0.75rem;
  }
  
  .post-description h1,
  .post-description h2,
  .post-description h3,
  .post-description h4,
  .post-description h5,
  .post-description h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .post-description ul,
  .post-description ol {
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
  }
  
  .post-description pre,
  .post-description code {
    background-color: var(--color-background);
    border-radius: 4px;
    padding: 0.2rem 0.4rem;
    font-family: monospace;
  }
  
  .post-description blockquote {
    border-left: 4px solid var(--color-text-secondary);
    padding-left: 1rem;
    margin-left: 0;
    font-style: italic;
  }
  
  .post-link {
    display: inline-block;
    color: var(--color-primary);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    margin-bottom: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  
  .post-link:hover {
    text-decoration: underline;
  }

  .post-meta {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-sm);
    color: var(--color-text-secondary);
    margin-bottom: 1rem;
  }

  .post-actions {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border: none;
    background: none;
    color: var(--color-text-secondary);
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s ease;
  }
  
  .action-button:hover:not(:disabled) {
    color: var(--color-primary);
    background-color: var(--color-hover);
  }
  
  .action-button.liked {
    color: var(--color-primary);
  }
  
  .action-button.liked svg {
    fill: currentColor;
  }
  
  .action-button.saved svg {
    fill: currentColor;
    color: var(--color-primary);
  }
  
  .share-message {
    text-align: center;
    color: var(--color-success);
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    background-color: var(--color-background);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .comments-section {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--color-text-tertiary);
  }
  
  .comments-section h5 {
    margin: 0 0 1rem;
    font-size: var(--font-size-md);
    color: var(--color-text-primary);
  }
  
  .comments-list {
    margin-bottom: 1rem;
  }
  
  .comment {
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    background-color: var(--color-background);
    border-radius: 8px;
  }
  
  .comment p {
    margin: 0 0 0.25rem;
    color: var(--color-text-primary);
  }
  
  .comment strong {
    color: var(--color-text-secondary);
    margin-right: 0.5rem;
  }
  
  .comment small {
    display: block;
    color: var(--color-text-tertiary);
    font-size: var(--font-size-sm);
  }
  
  .load-more-button {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid var(--color-text-tertiary);
    border-radius: 8px;
    background-color: var(--color-background);
    color: var(--color-text-primary);
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .load-more-button:hover {
    background-color: var(--color-hover);
  }
  
  .comment-form {
    margin-top: 12px;
  }
  
  .input-wrapper {
    position: relative;
    width: 100%;
  }
  
  .comment-input {
    width: 100%;
    min-height: 39px;
    max-height: 150px;
    resize: vertical;
    padding: 10px 40px 8px 12px;
    border: 1px solid var(--color-background);
    border-radius: 20px;
    font-size: var(--font-size-sm);
    font-family: inherit;
    overflow-y: auto;
    transition: height 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
    margin-bottom: 0;
    color: var(--color-text-primary);
  }
  
  .comment-input:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px var(--color-focus);
  }
  
  .send-button {
    position: absolute;
    right: 4px;
    bottom: 10px;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s ease;
  }
  
  .send-button:hover:not(:disabled) {
    background-color: var(--color-primary-dark);
  }
  
  .send-button:active {
    transform: scale(0.95);
  }
  
  .send-button svg {
    width: 18px;
    height: 18px;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @media (max-width: 360px) {
    .post-actions {
      justify-content: space-around;
    }
  
    .action-button {
      padding: 0.25rem;
    }
  
    .action-button svg {
      width: 18px;
      height: 18px;
    }
  }