/* src/components/PostItem.module.css */

.postItem {
  background-color: var(--color-surface);
  border-radius: 16px; /* Increased from 12px for a more modern look */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Lighter shadow for mobile */
  margin-bottom: 12px; /* Less spacing for mobile screens */
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smoother animation curve */
  border: 1px solid rgba(0, 0, 0, 0.04); /* Subtle border */
  max-width: 100%; /* Ensure fit on mobile screens */
  width: 100%;
}

.postItem.expanded {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px); /* Subtle lift effect when expanded */
}

.postHeader {
  cursor: pointer;
  padding: 20px 24px 16px; /* More breathing room */
  position: relative;
  transition: background-color 0.2s ease;
}

.postHeader:hover {
  background-color: rgba(0, 0, 0, 0.01); /* Subtle hover effect */
}

.postContent {
  padding: 0 24px; /* Consistent horizontal padding */
  transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1), 
              opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  max-height: 120px; /* Slightly increased */
  overflow: hidden;
  position: relative;
}

.postContent.collapsed::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--color-surface) 90%);
  pointer-events: none;
}

.postItem.expanded .postContent {
  max-height: none;
}

.postItem.expanded .postContent::after {
  display: none;
}

.postHeader {
  position: relative; /* To position the menu icon */
}

.postTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Changed from center for better alignment with multi-line titles */
  padding-right: 28px; /* Make space for the menu icon */
}

.postTitle {
  margin: 0 0 12px; /* Increased bottom margin */
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text-primary);
  line-height: 1.3; /* Improved readability */
}

.menuIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-secondary);
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease, transform 0.3s ease, color 0.2s ease;
  z-index: 2;
}

.menuIcon:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--color-text-primary);
}

/* Add animation to the menu icon when menu is open */
.menuIconActive {
  transform: rotate(90deg);
  color: var(--color-primary);
  background-color: rgba(var(--color-primary-rgb), 0.1);
}

.postTitle a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s ease;
}

.postTitle a:hover {
  color: var(--color-primary);
}

.expandIcon {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  flex-shrink: 0;
  margin: 0 0 8px 12px;
  opacity: 0.6; /* Reduced visibility when not hovered */
}

.postHeader:hover .expandIcon {
  opacity: 1;
}

.expandIcon.expanded {
  transform: rotate(180deg);
}

.posterInfo {
  font-size: var(--font-size-sm);
  color: var(--color-text-secondary);
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.avatarLink {
  margin-right: 12px;
  text-decoration: none;
  display: block;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  background-color: var(--color-background);
}

.posterInfoText {
  display: flex;
  flex-direction: column;
}

.posterName {
  font-weight: var(--font-weight-medium);
  color: var(--color-text-primary);
}

.posterUsername {
  color: var(--color-text-tertiary);
  font-size: var(--font-size-xs);
}

.postCreatedAt {
  margin-left: auto;
  color: var(--color-text-tertiary);
  font-size: var(--font-size-xs);
}

.postDescription {
  font-size: var(--font-size-md);
  color: var(--color-text-secondary);
  margin-bottom: 16px;
  line-height: 1.5; /* Improved readability */
}

/* Styles for markdown content remain similar but with refinements */
.postDescription h1,
.postDescription h2,
.postDescription h3,
.postDescription h4,
.postDescription h5,
.postDescription h6 {
  margin-top: 20px;
  margin-bottom: 12px;
  color: var(--color-text-primary);
  line-height: 1.3;
}

.postDescription p {
  margin-bottom: 12px;
}

.postDescription ul,
.postDescription ol {
  margin-bottom: 16px;
  padding-left: 24px;
}

.postDescription pre,
.postDescription code {
  background-color: var(--color-background);
  border-radius: 6px;
  padding: 4px 6px;
  font-family: monospace;
  font-size: 0.9em;
}

.postDescription blockquote {
  border-left: 4px solid var(--color-primary);
  padding: 4px 16px;
  margin: 16px 0;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 0 4px 4px 0;
}

.postLink {
  display: inline-flex;
  align-items: center;
  color: var(--color-primary);
  text-decoration: none;
  font-weight: var(--font-weight-medium);
  margin-bottom: 16px;
  font-size: var(--font-size-sm);
  background-color: rgba(var(--color-primary-rgb), 0.05);
  padding: 6px 1px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.postLink:hover {
  background-color: rgba(var(--color-primary-rgb), 0.1);
}

.postLink svg {
  margin-right: 8px;
}

.postMetadata {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  font-size: var(--font-size-xs);
  color: var(--color-text-tertiary);
}

.postPrivacy {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  text-transform: capitalize;
}

.postPrivacy svg {
  opacity: 0.7;
}

.postActions {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  border-top: 1px solid var(--color-background);
  gap: 8px;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-secondary);
  background: none;
  border: none;
  cursor: pointer;
  padding: 12px;
  min-height: 48px; /* Minimum touch target size */
  border-radius: 8px;
  transition: all 0.2s ease;
  flex: 1;
  max-width: fit-content;
}

.actionButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--color-primary);
}

.actionButton.active {
  color: var(--color-primary);
}

.actionButton svg {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.actionButton span {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.actionButtonGroup {
  display: flex;
  margin-left: auto;
  gap: 4px;
}

.actionButtonGroup .actionButton {
  padding: 8px;
}

.actionButtonGroup .actionButton svg {
  margin-right: 0;
}

.postComments {
  padding: 16px 24px;
  border-top: 1px solid var(--color-background);
  transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1),
              opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
              padding 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  max-height: 2000px; /* Large enough to contain typical comment sections */
  opacity: 1;
  overflow: hidden;
}

.postComments.hidden {
  max-height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.commentsHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
}

.commentsHeading .count {
  color: var(--color-text-tertiary);
  font-weight: normal;
  margin-left: 8px;
}

.commentForm {
  margin-top: 16px;
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.commentInput {
  width: 100%;
  min-height: 44px; /* Increased for better tap targets */
  max-height: 150px;
  resize: vertical;
  padding: 12px 44px 12px 16px; /* Increased padding */
  border: 1px solid var(--color-background);
  border-radius: 24px; /* More rounded */
  font-size: var(--font-size-sm);
  font-family: inherit;
  overflow-y: auto;
  transition: all 0.2s ease;
  margin-bottom: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.commentInput:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.1);
}

.sendButton {
  position: absolute;
  right: 4px;
  bottom: 10px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 6px rgba(var(--color-primary-rgb), 0.3);
}

.sendButton:hover {
  background-color: var(--color-primary-dark);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(var(--color-primary-rgb), 0.4);
}

.sendButton:active {
  transform: scale(0.95);
}

.shareMessage {
  text-align: center;
  color: var(--color-success);
  padding: 12px;
  font-size: var(--font-size-sm);
  background-color: rgba(var(--color-success-rgb), 0.1);
  border-radius: 8px;
  margin: 8px 24px;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Mobile-first design - base styles are already for mobile */
.postItem {
  /* Base styles are already good for mobile */
  margin-bottom: 12px; /* Reduced space between posts on mobile */
}

.postHeader {
  padding: 16px 16px 12px; /* More compact padding for mobile */
}

.postContent {
  padding: 0; /* Consistent with header padding */
}

.postActions {
  justify-content: space-around; /* Better distribution on narrow screens */
  padding: 8px 12px; /* Reduced padding for mobile */
}

.actionButton {
  flex: 1;
  justify-content: center;
  padding: 12px 8px;
  min-height: 48px; /* Touch-friendly */
}

.actionButton svg {
  width: 22px; /* Slightly larger icons for touch */
  height: 22px;
  margin-right: 4px; /* Less space between icon and text on mobile */
}

.actionMenu {
  position: absolute;
  top: 48px; /* Position the menu below the icon */
  right: 12px;
  background: var(--color-surface);
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 10;
  min-width: 180px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.04);
  /* Animation properties */
  opacity: 0;
  transform: scale(0.1);
  transform-origin: calc(100% - 12px) 0; /* Align with the icon position */
  transition: opacity 0.25s ease, 
              transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1); /* Bouncy effect */
  pointer-events: none; /* Disable interaction when hidden */
  will-change: transform, opacity; /* Optimize animation performance */
}

/* Visible state for the action menu */
.actionMenuVisible {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto; /* Enable interaction when visible */
}

.actionMenuItem {
  display: flex;
  align-items: center;
  min-height: 48px; /* Touch-friendly */
  gap: 12px;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease, opacity 0.2s ease;
  color: var(--color-text-primary);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-normal);
  opacity: 0;
  transform: translateX(10px);
}

/* Animation for menu items */
.actionMenuVisible .actionMenuItem {
  opacity: 1;
  transform: translateX(0);
}

/* Staggered animation for menu items */
.actionMenuVisible .actionMenuItem:nth-child(1) {
  transition-delay: 0.05s;
}

.actionMenuVisible .actionMenuItem:nth-child(2) {
  transition-delay: 0.1s;
}

.actionMenuVisible .actionMenuItem:nth-child(3) {
  transition-delay: 0.15s;
}

.actionMenuVisible .actionMenuItem:nth-child(4) {
  transition-delay: 0.2s;
}

.actionMenuItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: translateX(5px); /* Subtle hover effect */
}

/* Styling for delete button */
.deleteAction {
  color: var(--color-error, #e53935);
}

.deleteAction:hover {
  background-color: rgba(229, 57, 53, 0.1);
}

.postComments {
  padding: 12px 16px; /* Reduced padding */
}

.sendButton {
  width: 37px; /* Larger touch target */
  height: 37px;
}

/* Then we make desktop-specific adjustments */
@media (min-width: 768px) {
  .postItem {
    margin-bottom: 24px;
  }
  
  .postHeader {
    padding: 20px 24px 16px;
  }
  
  .postContent {
    padding: 0 24px;
  }
  
  .postActions {
    padding: 12px 24px;
    gap: 8px;
  }
  
  .actionButton {
    padding: 8px 12px;
  }
  
  .actionButton svg {
    margin-right: 6px;
  }
  
  .postComments {
    padding: 16px 24px;
  }
  
  /* Desktop-specific changes */
  .actionButtonGroup {
    margin-left: auto;
  }
}