/* FriendItem.module.css */

.friendItem {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--color-background);
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.friendItem:last-child {
  border-bottom: none;
}

.friendItem:hover {
  background-color: var(--color-hover);
}

.friendItem:active {
  transform: scale(0.98);
  background-color: var(--color-hover);
}

/* Focus Styles for Accessibility */
.friendItem:focus {
  outline: none;
}

.friendItem:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-focus);
  border-radius: 8px;
}

.friendItem.highlight .friendAvatar {
  border: 2px solid var(--color-primary);
}

/* Avatar Styles */
.friendAvatar {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  overflow: hidden;
}

.friendAvatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.friendAvatarLetter {
  font-size: 24px;
}

.friendAvatar::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: 50%;
  border: 2px solid transparent;
}

.friendItem.highlight .friendAvatar::after {
  border: 2px solid var(--color-primary);
}

.friendInfo {
  display: flex;
  flex-direction: column;
}

.friendName {
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-md);
}

.friendUsername {
  color: var(--color-text-secondary);
  font-size: var(--font-size-sm);
}