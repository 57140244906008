/* src/components/Header.css */
.app-header {
  background-color: var(--color-surface);
  border-bottom: 0.5px solid var(--color-text-tertiary);
  padding: 0.3rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 30;
}

.logo-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.logo {
  height: 32px;
  width: auto;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.header-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: none;
  background: none;
  color: var(--color-text-primary);
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  transition: background-color 0.2s;
}

.header-button:hover {
  background-color: var(--color-hover);
}

.notifications-button {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-error);
  color: var(--color-surface);
  font-size: 0.75rem;
  padding: 0.125rem 0.375rem;
  border-radius: 1rem;
  min-width: 1.25rem;
  text-align: center;
}