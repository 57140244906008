/* src/components/LanguageSwitcher/LanguageSwitcher.css */

.settings-section {
  margin-bottom: 1rem;
}

/* When used as a standalone dropdown */
.language-switcher-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-surface);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
}

.settings-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-primary);
  transition: background-color 0.2s ease;
  font-size: 1rem;
  line-height: 1.5;
}

.settings-button:hover {
  background-color: var(--color-hover);
}

.settings-button.active {
  font-weight: 600;
  color: var(--color-primary);
}

.settings-button.active::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color-success);
  margin-left: auto;
}

.settings-button-icon {
  margin-right: 0.75rem;
  color: var(--color-text-secondary);
  flex-shrink: 0;
}

.settings-button-content {
  flex-grow: 1;
}