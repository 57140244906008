/* GroupCreateModal.module.css */

.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  backdrop-filter: blur(4px);
  z-index: 50;
}

.modalContent {
  background-color: var(--color-surface);
  border-radius: 1rem;
  width: 100%;
  max-width: 28rem;
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.modalHeader {
  border-bottom: 1px solid var(--color-border);
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalHeader h2 {
  font-size: 1.125rem;
  font-weight: 600;
  flex: 1;
  text-align: center;
  margin-bottom: 0;
}

.closeButton {
  color: var(--color-text-secondary);
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: color 0.2s;
}

.closeButton:hover {
  color: var(--color-text);
}

.createButton {
  color: var(--color-primary);
  font-size: var(--font-size-md);
  font-weight: 600;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  transition: opacity 0.2s;
}

.createButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.groupInfo {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid var(--color-border);
}

.iconUploadContainer {
  position: relative;
}

.hiddenInput {
  display: none;
}

.iconLabel {
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.2s;
}

.iconLabel:hover {
  background-color: var(--color-background-hover);
}

.iconPreview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cameraIcon {
  color: var(--color-text-secondary);
}

.groupNameInput {
  flex: 1;
  border: none;
  background: none;
  font-size: 1.125rem;
  padding: 0.5rem;
  margin-bottom: 0;
}

.groupNameInput:focus {
  outline: none;
}

.searchContainer {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid var(--color-border);
}

.searchWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchIcon {
  position: absolute;
  left: 0.75rem;
  color: var(--color-text-secondary);
  pointer-events: none;
}

.searchInput {
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  margin-bottom: 0;
  border-radius: 9999px;
  background-color: var(--color-background);
  border: none;
  transition: background-color 0.2s;
}

.searchInput:focus {
  outline: none;
  background-color: var(--color-background-hover);
}

.selectedFriends {
  padding: 0.75rem 1.5rem;
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  border-bottom: 1px solid var(--color-border);
  scrollbar-width: none;
}

.selectedFriends::-webkit-scrollbar {
  display: none;
}

.selectedFriendItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  min-width: 4rem;
}

.selectedFriendImageContainer {
  position: relative;
}

.selectedFriendImage {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
}

.removeButton {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  background-color: var(--color-background);
  border: none;
  border-radius: 50%;
  padding: 0.125rem;
  cursor: pointer;
  color: var(--color-text-secondary);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s;
}

.removeButton:hover {
  background-color: var(--color-background-hover);
  color: var(--color-text);
}

.selectedFriendName {
  font-size: 0.75rem;
  color: var(--color-text);
  max-width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.friendsList {
  max-height: 350px;
  overflow-y: auto;
  padding: 0.5rem 0;
}

.friendsList::-webkit-scrollbar {
  width: 0.375rem;
}

.friendsList::-webkit-scrollbar-track {
  background-color: var(--color-background);
}

.friendsList::-webkit-scrollbar-thumb {
  background-color: var(--color-border);
  border-radius: 0.375rem;
}

.noFriendsMessage {
  text-align: center;
  color: var(--color-text-secondary);
  padding: 2rem 1rem;
}

.errorMessage {
  margin: 1rem;
  padding: 0.75rem 1rem;
  background-color: var(--color-error-light);
  color: var(--color-error);
  border-radius: 0.5rem;
  font-size: 0.875rem;
}