.notification-banner {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 12px 20px;
    z-index: 1000;
    max-width: 90%;
    width: 400px;
    cursor: pointer;
  }
  
  .notification-banner:hover {
    background-color: #f5f5f5;
  }
  
  .notification-title {
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .notification-content {
    margin: 0;
    color: #666;
  }
  
  @media (max-width: 480px) {
    .notification-banner {
      width: 90%;
    }
  }