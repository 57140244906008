/* src/components/GroupButton.css */

.group-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 16px;
    border: none;
    border-radius: 25px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .group-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .group-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .group-button:focus {
    outline: none;
  }
  
  .group-button:focus-visible {
    box-shadow: 0 0 0 2px var(--color-surface), 0 0 0 4px var(--color-accent-1);
  }
  
  /* Button Container */
  .group-button-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Icon Container */
  .group-button-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
  }

  .group-button-img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  
  .group-button:hover .group-button-icon {
    transform: rotate(-12deg);
  }
  
  /* Badge */
  .group-button-badge {
    position: absolute;
    top: -4px;
    right: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: var(--color-primary);
    color: var(--color-surface);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-bold);
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Text */
  .group-button-text {
    margin-left: 8px;
    color: var(--color-text-primary);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .group-button {
      padding: 0 5px;
    }
  
    .group-button-text {
      display: none;
    }
  }