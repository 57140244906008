/* src/components/SkeletonGroupChat.css */

/* Skeleton Loading Styles */
.message-skeleton {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding-left: 50px;
  position: relative;
  animation: fade 1.5s infinite;
}

.skeleton-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-background);
  position: absolute;
  left: 0;
  top: 0;
}

.skeleton-name {
  width: 120px;
  height: 16px;
  background-color: var(--color-background);
  border-radius: 4px;
  margin-bottom: 8px;
}

.skeleton-message {
  width: 60%;
  height: 32px;
  background-color: var(--color-background);
  border-radius: 1rem;
  border-top-left-radius: 4px;
}

.message-skeleton.sent {
  padding-left: 0;
  align-items: flex-end;
}

.message-skeleton.sent .skeleton-message {
  border-radius: 1rem;
  border-bottom-right-radius: 4px;
}

@keyframes fade {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* Mobile responsive adjustments for skeleton */
@media (max-width: 768px) {
  .message-skeleton {
    padding-left: 39px;
  }
  
  .skeleton-avatar {
    width: 32px;
    height: 32px;
  }
  
  .skeleton-name {
    width: 100px;
    height: 14px;
  }
}