/* src/components/FriendSearch/FriendSearch.css */

.friend-search-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .friend-search-section {
    margin-top: 10px;
  }
  
  .friend-search-wrapper {
    position: relative;
    margin-bottom: 20px;
  }
  
  .friend-search-input {
    width: 100%;
    padding: 15px 20px;
    padding-right: 40px;
    font-size: 16px;
    border: 1px solid var(--color-text-tertiary);
    border-radius: 25px;
    outline: none;
    transition: border-color 0.2s;
    background-color: var(--color-surface);
    color: var(--color-text-primary);
    margin-bottom: 0;
  }
  
  .friend-search-input:focus {
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px var(--color-focus);
  }
  
  .friend-search-clear {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 18px;
    color: var(--color-text-tertiary);
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .friend-search-clear:hover {
    color: var(--color-text-secondary);
  }
  
  .friend-search-error {
    color: var(--color-error);
    margin: 10px 0;
    padding: 10px;
    background-color: var(--color-error-bg);
    border-radius: 4px;
    text-align: center;
  }
  
  .friend-search-loading {
      text-align: center;
      padding: 20px;
      color: var(--color-text-secondary);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  
    .friend-search-loading::after {
      content: "";
      width: 20px;
      height: 20px;
      border: 2px solid var(--color-text-secondary);
      border-right-color: transparent;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }
  
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  
  .friend-search-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: var(--color-surface);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    margin-bottom: 10px;
    transition: all 0.2s ease;
    cursor: pointer;
    outline: none;
  }
  
  .friend-search-card:hover {
    background-color: var(--color-surface-hover);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }


  .friend-search-card:focus {
    box-shadow: 0 0 0 2px var(--color-primary);
    border-color: var(--color-primary);
  }
  
  .friend-search-user-info {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .friend-search-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .friend-search-avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .friend-search-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .friend-search-details h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-text-primary);
    line-height: 1.2;
  }
  
  .friend-search-username {
    margin: 2px 0;
    font-size: 14px;
    color: var(--color-text-secondary);
    line-height: 1.2;
  }
  
  .friend-search-mutual {
    margin: 2px 0 0 0;
    font-size: 12px;
    color: var(--color-text-tertiary);
    line-height: 1.2;
  }
  
  .friend-search-add-btn {
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid var(--color-primary);
    background-color: transparent;
    color: var(--color-primary);
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s;
    white-space: nowrap;
    line-height: 1.2;
    position: relative;
    z-index: 1; /* Ensure button stays above card for clicks */
  }
  
  .friend-search-add-btn:hover:not(:disabled) {
    background-color: var(--color-primary);
    color: white;
    transform: translateY(-1px);
  }

  .friend-search-add-btn:active:not(:disabled) {
    transform: translateY(0);
  }
  
  .friend-search-add-btn.requested {
    background-color: var(--color-surface-variant);
    border-color: var(--color-surface-variant);
    color: var(--color-text-secondary);
    cursor: default;
  }
  
  .friend-search-add-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .friend-search-no-results {
    text-align: center;
    padding: 20px;
    color: var(--color-text-secondary);
    background-color: var(--color-surface-variant);
    border-radius: 8px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .friend-search-container {
      padding: 10px;
    }
  
    .friend-search-input {
      padding: 12px 15px;
      font-size: 14px;
    }
  
    .friend-search-card {
      padding: 12px;
    }
  
    .friend-search-details h3 {
      font-size: 15px;
    }
  
    .friend-search-username {
      font-size: 13px;
    }
  
    .friend-search-mutual {
      font-size: 11px;
    }
  
    .friend-search-add-btn {
      padding: 6px 12px;
      font-size: 13px;
    }
  }
  
  /* Dark Mode Support */
  @media (prefers-color-scheme: dark) {
    .friend-search-card {
      border-color: var(--color-border-dark, var(--color-border));
    }
  
    .friend-search-card:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
