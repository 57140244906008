/* src/components/PostSkeleton.module.css */
.postSkeleton {
    background-color: var(--color-surface);
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin-bottom: 12px;
    padding: 16px;
    overflow: hidden;
    width: 100%;
  }
  
  .skeletonHeader {
    width: 100%;
  }
  
  .posterInfo {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .skeletonAvatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
  }
  
  .posterInfoText {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .skeletonName {
    height: 16px;
    width: 120px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  
  .skeletonUsername {
    height: 12px;
    width: 80px;
    border-radius: 4px;
  }
  
  .skeletonTime {
    height: 12px;
    width: 60px;
    border-radius: 4px;
    margin-left: auto;
  }
  
  .skeletonTitle {
    height: 24px;
    border-radius: 4px;
    margin-bottom: 12px;
    width: 90%;
  }
  
  .skeletonContent {
    margin-bottom: 16px;
  }
  
  .skeletonParagraph {
    height: 14px;
    border-radius: 4px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .skeletonActions {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    border-top: 1px solid var(--color-background);
  }
  
  .skeletonButton {
    height: 32px;
    width: 60px;
    border-radius: 8px;
  }
  
  /* Animation for shimmer loading effect */
  @keyframes shimmer {
    0% { background-position: -468px 0 }
    100% { background-position: 468px 0 }
  }
  
  .loadingShimmer {
    background: linear-gradient(to right, var(--color-background) 8%, var(--color-surface) 18%, var(--color-background) 33%);
    background-size: 800px 104px;
    animation: shimmer 1.5s linear infinite;
  }
  
  /* Detail view modifications */
  .detailView {
    margin: 16px 0;
    padding: 20px;
  }
  
  .detailView .skeletonTitle {
    width: 70%;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .postSkeleton {
      margin-bottom: 24px;
      padding: 20px;
    }
    
    .detailView {
      max-width: 680px;
      margin: 24px auto;
    }
  }