/* src/components/FriendRequests/FriendRequests.css */

.friend-requests {
    margin-bottom: 30px;
    background-color: var(--color-surface);
    border-radius: 8px;
    padding: 20px;
    border: 1px solid var(--color-border);
  }
  
  .friend-requests-title {
    margin: 0 0 20px 0;
    font-size: 20px;
    font-weight: 500;
    color: var(--color-text-primary);
  }
  
  .friend-requests-error {
    color: var(--color-error);
    margin: 10px 0;
    padding: 10px;
    background-color: var(--color-error-bg);
    border-radius: 4px;
    text-align: center;
  }
  
  .friend-requests-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .friend-request-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: var(--color-surface);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  
  .friend-request-card:hover {
    background-color: var(--color-surface-hover);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .friend-request-user {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
    min-width: 0;
    margin-right: 12px;
  }
  
  .friend-request-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .friend-request-avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .friend-request-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    flex: 1;
  }
  
  .friend-request-info h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-text-primary);
    line-height: 1.2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .friend-request-username {
    margin: 4px 0 0 0;
    font-size: 14px;
    color: var(--color-text-secondary);
    line-height: 1.2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .friend-request-actions {
    display: flex;
    gap: 8px;
    flex-shrink: 0;
  }
  
  .friend-request-button {
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
    border: none;
  }
  
  .friend-request-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .friend-request-button.accept {
    background-color: var(--color-primary);
    color: white;
    border: 1px solid var(--color-primary);
  }
  
  .friend-request-button.accept:hover:not(:disabled) {
    background-color: var(--color-primary-dark);
    border-color: var(--color-primary-dark);
    transform: translateY(-1px);
  }
  
  .friend-request-button.decline {
    background-color: transparent;
    color: var(--color-text-secondary);
    border: 1px solid var(--color-border);
  }
  
  .friend-request-button.decline:hover:not(:disabled) {
    background-color: var(--color-surface-hover);
    border-color: var(--color-text-secondary);
    transform: translateY(-1px);
  }
  
  /* Focus states for accessibility */
  .friend-request-card:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-primary);
  }
  
  .friend-request-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-primary);
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .friend-requests {
      padding: 0;
    }
  
    .friend-request-card {
      padding: 0;
    }
  
    .friend-request-info h3 {
      font-size: 15px;
    }
  
    .friend-request-username {
      font-size: 13px;
    }
  
    .friend-request-button {
      padding: 6px 12px;
      font-size: 13px;
    }
  }
  
  /* Processing state animation */
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  .friend-request-button:disabled {
    animation: pulse 1.5s infinite;
  }