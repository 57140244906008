/* src/components/BottomNavigation/BottomNavigation.css */

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-surface);
  border-top: 1px solid var(--color-text-tertiary);
  padding: 2px 0 calc(env(safe-area-inset-bottom, 16px) + 8px);
  z-index: 100;
}

.bottom-navigation-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.bottom-navigation a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 64px;
  color: var(--color-text-secondary);
  text-decoration: none;
  position: relative;
}

.bottom-navigation a.active {
  color: var(--color-primary);
}

.icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container svg {
  transition: transform 0.3s ease;
}

.bottom-navigation a.active .icon-container svg {
  transform: scale(1.1);
}

.indicator {
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  background-color: var(--color-primary);
  border-radius: 50%;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -4px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* Adjustments for larger screens */
@media (min-width: 768px) {
  .bottom-navigation {
    padding-bottom: calc(env(safe-area-inset-bottom, 8px) + 4px);
  }

  .bottom-navigation a {
    padding: 10px;
  }
}

/* Safe area handling for iOS devices */
@supports (padding: max(0px)) {
  .bottom-navigation {
    padding-bottom: max(env(safe-area-inset-bottom, 16px) + 8px, 24px);
  }
}