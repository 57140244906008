/* src/components/ChatPostSkeleton.css */

.post-skeleton {
    background-color: var(--color-surface);
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .post-skeleton-header {
    display: flex;
    align-items: center;
  }
  
  .post-skeleton-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-skeleton-base);
  }
  
  .post-skeleton-info {
    margin-left: 12px;
    flex: 1;
  }
  
  .post-skeleton-username {
    height: 16px;
    width: 25%;
    background-color: var(--color-skeleton-base);
    border-radius: 4px;
  }
  
  .post-skeleton-timestamp {
    height: 12px;
    width: 16.67%;
    background-color: var(--color-skeleton-base);
    border-radius: 4px;
    margin-top: 8px;
  }
  
  /* Animation */
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  
  .post-skeleton-avatar,
  .post-skeleton-username,
  .post-skeleton-timestamp {
    animation: pulse 1.5s ease-in-out infinite;
  }
