/* src/components/NotificationsDropdown.css */

.notifications-dropdown {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 340px;
  max-height: 85vh;
  overflow-y: auto;
  background-color: var(--color-surface);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.notifications-header {
  padding: 16px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-skeleton-base);
  position: sticky;
  top: 0;
  background-color: var(--color-surface);
  z-index: 1;
}

.notifications-header h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.notifications-header .close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.notifications-header .close-button:hover {
  background-color: var(--color-hover);
}

.notification-group {
  margin-bottom: 8px;
}

.notification-group .group-header {
  padding: 8px 16px;
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-text-secondary);
  background-color: var(--color-background);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  z-index: 0; /* Cancel out conflicting style */
}

.notifications-content {
  overflow-y: auto;
}

/* Loading state styles */
.loading-state {
  padding: 40px 16px;
  text-align: center;
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  margin-bottom: 16px;
  animation: spin 1.5s linear infinite;
  color: var(--color-primary-light);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error state styles */
.error-state {
  padding: 40px 16px;
  text-align: center;
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-state p {
  color: var(--color-error);
  margin-bottom: 8px;
}

.retry-button {
  margin-top: 12px;
  padding: 8px 16px;
  background-color: var(--color-background);
  border: 1px solid var(--color-skeleton-base);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text-primary);
  cursor: pointer;
  transition: all 0.2s;
}

.retry-button:hover {
  background-color: var(--color-skeleton-highlight);
  border-color: var(--color-primary-light);
  color: var(--color-primary);
}

/* Empty state styles */
.empty-state {
  padding: 40px 16px;
  text-align: center;
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-icon {
  background-color: var(--color-background);
  padding: 16px;
  border-radius: 50%;
  margin-bottom: 16px;
  color: var(--color-text-secondary);
}

.empty-state p:first-of-type {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--color-text-primary);
}

.empty-state p:last-of-type {
  font-size: 14px;
  margin-top: 0;
}

/* Animation for content transitions */
.notifications-content, .empty-state, .loading-state, .error-state {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Mobile screens */
@media (max-width: 768px) {
  .notifications-dropdown {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    box-shadow: none;
    border: none;
    transform: translateX(100%); /* Set initial off-screen state */
    animation: slideInFromRight 0.35s cubic-bezier(0.22, 0.61, 0.36, 1) forwards; /* Use a keyframes animation for the slide-in effect */
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }  
  
  .notifications-header {
    padding: 16px;
    border-bottom: 1px solid var(--color-skeleton-base);
  }
}