/* SelectableFriendItem.module.css */

.friendItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 1.5rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.friendItem:hover {
  background-color: var(--color-background-hover);
}

.friendItem.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.friendInfo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
}

.name {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-text-primary);
}

.checkbox {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid var(--color-border);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.checkbox.checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.checkIcon {
  color: var(--color-surface);
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.2s ease;
}

.checked .checkIcon {
  opacity: 1;
  transform: scale(1);
}