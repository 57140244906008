/* src/components/PostEditForm.module.css */

.postEditForm {
    padding: 16px;
    animation: fadeIn 0.3s ease;
    background-color: var(--color-surface);
    border-radius: 16px;
  }
  
  .editFormHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-background);
    flex-wrap: wrap;
    gap: 12px;
  }
  
  .editFormTitle {
    margin: 0;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-primary);
  }
  
  .toggleSwitch {
    position: relative;
    display: flex;
    background-color: var(--color-background);
    border-radius: 30px;
    padding: 3px;
    height: 36px;
    min-width: 200px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
    overflow: hidden; /* Important for the slider animation */
  }
  
  .toggleOption {
    position: relative;
    z-index: 2;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 0 12px;
    border: none;
    background: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--color-text-secondary);
    transition: color 0.3s ease;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
  }
  
  .toggleOption:focus {
    outline: none;
  }
  
  .toggleOption.toggleActive {
    color: var(--color-primary);
  }
  
  .toggleSlider {
    position: absolute;
    top: 3px;
    left: 3px;
    right: 50%;
    bottom: 3px;
    background-color: var(--color-surface);
    border-radius: 30px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .toggleSlider.sliderRight {
    left: 45%; /* Resize the button to fit the longer "Preview" text */
    right: 3px;
  }
  
  .formGroup {
    margin-bottom: 20px;
    position: relative;
  }
  
  .formLabel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    font-weight: var(--font-weight-medium);
    color: var(--color-text-primary);
    font-size: var(--font-size-sm);
  }
  
  .labelText {
    display: flex;
    align-items: center;
  }
  
  .requiredFlag {
    color: var(--color-error);
    margin-left: 4px;
  }
  
  .markdownHelp {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--color-text-tertiary);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-normal);
  }

  .markdownHelpContainer {
    margin-left: auto;
  }  
  
  .markdownHelp svg {
    color: var(--color-text-tertiary);
  }
  
  .formInput {
    width: 100%;
    padding: 10px 12px;
    margin-bottom: 0; /* Remove default margin */
    border: 1px solid var(--color-background);
    border-radius: 12px;
    font-size: var(--font-size-md);
    transition: all 0.2s ease;
    background-color: var(--color-background);
    color: var(--color-text-primary);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
    -webkit-appearance: none; /* Remove iOS default styling */
    appearance: none;
  }
  
  .formTextarea {
    min-height: 120px; /* Minimum height */
    height: auto; /* Allow dynamic height */
    resize: none; /* Disable manual resizing since we're handling it automatically */
    line-height: 1.6;
    font-family: inherit;
    font-size: 16px; /* Prevent zoom on focus in iOS */
    overflow: hidden; /* Hide scrollbars when not needed */
    padding: 14px 16px;
    margin-bottom: 0; /* Remove default margin */
    border: 1px solid var(--color-background);
    border-radius: 12px;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    background-color: var(--color-background);
    color: var(--color-text-primary);
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
    -webkit-appearance: none; /* Remove iOS default styling */
    appearance: none;
  }
  
  .formInput:focus,
  .formTextarea:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.1);
  }
  
  .formInput:hover,
  .formTextarea:hover {
    border-color: var(--color-text-tertiary);
  }
  
  .validationError {
    color: var(--color-error);
    font-size: var(--font-size-xs);
    margin-top: 6px;
    display: block;
    animation: slideInUp 0.2s ease;
  }
  
  @keyframes slideInUp {
    from { opacity: 0; transform: translateY(5px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .charCounter {
    display: flex;
    justify-content: flex-end;
    color: var(--color-text-tertiary);
    font-size: var(--font-size-xs);
    margin-bottom: -15px;
  }
  
  .charCountWarning {
    color: var(--color-warning);
  }
  
  .charLimit {
    color: var(--color-text-tertiary);
  }
  
  .linkInputWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .testLinkButton {
    position: absolute;
    right: 12px;
    color: var(--color-text-tertiary);
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .testLinkButton:hover {
    color: var(--color-primary);
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .privacySelector {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .privacyOption {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid var(--color-background);
    background-color: var(--color-surface);
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
    width: 100%;
    -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
  }
  
  .privacyOption:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border-color: var(--color-text-tertiary);
  }
  
  .privacyOption.selected {
    border-color: var(--color-primary);
    background-color: rgba(var(--color-primary-rgb), 0.04);
  }
  
  .privacyIconContainer {
    position: relative;
    width: 42px;
    height: 42px;
    background-color: var(--color-background);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: all 0.2s ease;
  }
  
  .privacyOption.selected .privacyIconContainer {
    background-color: var(--color-primary);
  }
  
  .privacyOption svg {
    color: var(--color-text-secondary);
    transition: color 0.2s ease;
  }
  
  .privacyOption.selected svg {
    color: white;
  }
  
  .selectionIndicator {
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--color-primary);
    border: 2px solid var(--color-surface);
    opacity: 0;
    transform: scale(0);
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275); /* Bouncy effect */
  }
  
  .privacyOption.selected .selectionIndicator {
    opacity: 1;
    transform: scale(1);
  }
  
  .privacyContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 2px;
  }
  
  .privacyLabel {
    font-weight: var(--font-weight-medium);
    color: var(--color-text-primary);
    display: block;
    margin-bottom: 4px;
    font-size: var(--font-size-md);
  }
  
  .privacyDescription {
    font-size: var(--font-size-xs);
    color: var(--color-text-tertiary);
    display: block;
    line-height: 1.4;
  }  
  
  .previewContent {
    background-color: var(--color-background);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    animation: fadeIn 0.3s ease;
  }
  
  .previewTitle {
    margin: 0 0 16px;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-primary);
  }
  
  .previewDescription {
    font-size: var(--font-size-md);
    color: var(--color-text-secondary);
    margin-bottom: 16px;
    line-height: 1.6;
  }
  
  .previewFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: var(--font-size-xs);
    color: var(--color-text-tertiary);
  }
  
  .postLink {
    display: inline-flex;
    align-items: center;
    color: var(--color-primary);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    margin-bottom: 16px;
    font-size: var(--font-size-sm);
    background-color: rgba(var(--color-primary-rgb), 0.05);
    padding: 8px 12px;
    border-radius: 8px;
    transition: background-color 0.2s ease;
  }
  
  .postLink:hover {
    background-color: rgba(var(--color-primary-rgb), 0.1);
  }
  
  .postLink svg {
    margin-right: 8px;
  }
  
  .postPrivacy {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    text-transform: capitalize;
  }
  
  .postPrivacy svg {
    opacity: 0.7;
  }
  
  .postEditActions {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-top: 24px;
    padding-top: 20px;
    border-top: 1px solid var(--color-background);
  }
  
  .cancelButton, 
  .saveButton {
    flex: 1;
    padding: 14px 0;
    font-size: var(--font-size-md);
    min-height: 50px; /* Larger buttons for mobile */
  }
  
  .saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .spinnerIcon {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Styles for markdown content in the preview */
  .previewDescription h1,
  .previewDescription h2,
  .previewDescription h3,
  .previewDescription h4,
  .previewDescription h5,
  .previewDescription h6 {
    margin-top: 20px;
    margin-bottom: 12px;
    color: var(--color-text-primary);
    line-height: 1.3;
  }
  
  .previewDescription h1 {
    font-size: 1.8em;
  }
  
  .previewDescription h2 {
    font-size: 1.5em;
  }
  
  .previewDescription h3 {
    font-size: 1.3em;
  }
  
  .previewDescription p {
    margin-bottom: 12px;
  }
  
  .previewDescription ul,
  .previewDescription ol {
    margin-bottom: 16px;
    padding-left: 24px;
  }
  
  .previewDescription pre,
  .previewDescription code {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 4px 6px;
    font-family: monospace;
    font-size: 0.9em;
  }
  
  .previewDescription blockquote {
    border-left: 4px solid var(--color-primary);
    padding: 4px 16px;
    margin: 16px 0;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 0 4px 4px 0;
  }
  
  .previewDescription a {
    color: var(--color-primary);
    text-decoration: none;
  }
  
  .previewDescription a:hover {
    text-decoration: underline;
  }
  
  .previewDescription img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 12px 0;
  }

  /* Additional mobile optimization */
  @media (max-width: 400px) {
    .toggleOption span {
      display: none; /* Hide text on very small screens, show only icons */
    }
    
    .toggleSwitch {
      min-width: 90px;
    }

    .toggleSlider.sliderRight {
      left: 50%; 
    }
    
    .privacyOption {
      padding: 10px;
    }
    
    .privacyIconContainer {
      width: 38px;
      height: 38px;
    }
    
    .privacyContent {
      padding-top: 0;
    }
    
    /* Active/press state for mobile - touch feedback */
    .privacyOption:active {
      background-color: rgba(var(--color-primary-rgb), 0.08);
      transform: scale(0.98);
    }
  }  
  
  /* Desktop-specific adjustments */
  @media (min-width: 768px) {
    .postEditForm {
      padding: 24px;
    }
    
    .editFormHeader {
      margin-bottom: 24px;
    }

    .formInput {
        padding: 14px 16px;        
    }
    
    .privacySelector {
      gap: 12px;
    }
    
    .cancelButton, 
    .saveButton {
      flex: 0 0 auto;
      min-width: 120px;
      padding: 10px 16px;
      min-height: 44px;
    }
  }