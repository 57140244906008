/* src/components/GroupList.css */

.group-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-surface);
}

.group-list-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--color-surface);
  border-bottom: 1px solid var(--color-hover);
  padding: 1rem;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.header-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.header-title h1 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--color-text-primary);
  margin-bottom: 0;
}

.title-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-text-secondary);
}

.create-group-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  background-color: var(--color-primary);
  color: var(--color-surface);
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-group-button:hover {
  background-color: var(--color-primary-light);
}

.create-group-button:active {
  background-color: var(--color-primary-dark);
}

.create-group-button svg {
  width: 1.25rem;
  height: 1.25rem;
}

.group-list .search-container {
  position: relative;
  margin-top: 0.5rem;
}

.group-list .search-icon {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
  color: var(--color-text-tertiary);
}

.group-list .search-input {
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  border-radius: 9999px;
  border: none;
  background-color: var(--color-background);
  font-size: 0.875rem;
  color: var(--color-text-primary);
  transition: all 0.2s;
  margin-bottom: 0;
}

.group-list .search-input:focus {
  outline: none;
  background-color: var(--color-surface);
  box-shadow: 0 0 0 2px var(--color-focus);
}

.group-list .search-input::placeholder {
  color: var(--color-text-tertiary);
}

.group-list-content {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem 0;
}

.group-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1rem;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid var(--color-background);
  transition: background-color 0.2s;
  gap: 0.75rem;
}

.group-item:hover {
  background-color: var(--color-hover);
}

.group-item:active {
  background-color: var(--color-background);
}

.group-avatar {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  overflow: hidden;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-initials {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-text-tertiary);
  color: var(--color-surface);
  font-weight: 500;
  font-size: 1rem;
}

.avatar-initials.unread {
  background-color: var(--color-primary);
}

.group-details {
  flex: 1;
  min-width: 0;
}

.group-details-top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.25rem;
}

.group-name {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-text-primary);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unread .group-name {
  font-weight: 600;
  color: var(--color-on-surface);
}

.last-active {
  flex-shrink: 0;
  font-size: 0.75rem;
  color: var(--color-text-secondary);
  margin-left: 0.5rem;
}

.group-details-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.last-message {
  font-size: 0.875rem;
  color: var(--color-text-secondary);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unread-badge {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.25rem;
  height: 1.25rem;
  padding: 0 0.375rem;
  border-radius: 9999px;
  background-color: var(--color-primary);
  color: var(--color-surface);
  font-size: 0.75rem;
  font-weight: 500;
  margin-left: 0.5rem;
}

/* Skeleton Loading Styles */
@keyframes group-list-shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.group-list-skeleton {
  pointer-events: none;
}

.group-list-skeleton-avatar {
  background: linear-gradient(
    90deg,
    var(--color-background) 25%,
    var(--color-hover) 50%,
    var(--color-background) 75%
  );
  background-size: 200% 100%;
  animation: group-list-shimmer 1.5s infinite;
}

.group-list-skeleton-text {
  height: 1rem;
  background: linear-gradient(
    90deg,
    var(--color-background) 25%,
    var(--color-hover) 50%,
    var(--color-background) 75%
  );
  background-size: 200% 100%;
  animation: group-list-shimmer 1.5s infinite;
  border-radius: 4px;
}

.group-list-skeleton-name {
  width: 60%;
  height: 1.25rem;
  margin-bottom: 0.25rem;
}

.group-list-skeleton-time {
  width: 3rem;
  height: 0.875rem;
}

.group-list-skeleton-message {
  width: 80%;
  height: 1rem;
}

/* Add responsive styles for larger screens */
@media (min-width: 640px) {
  .group-list {
    max-width: 35rem;
    margin: 0 auto;
    border-right: 1px solid var(--color-hover);
    border-left: 1px solid var(--color-hover);
  }
  
  .group-item {
    padding: 1rem;
  }
  
  .group-avatar {
    width: 3.5rem;
    height: 3.5rem;
  }
}

/* Add system-level dark mode support */
/* @media (prefers-color-scheme: dark) {
  .group-list {
    background-color: var(--color-accent-3);
  }

  .group-list-header {
    background-color: var(--color-accent-3);
    border-bottom-color: var(--color-text-tertiary);
  }

  .header-title h1 {
    color: var(--color-surface);
    margin-bottom: 0;
  }

  .title-icon {
    color: var(--color-text-secondary);
  }

  .search-input {
    background-color: var(--color-text-primary);
    color: var(--color-surface);
    margin-bottom: 0;
  }

  .search-input:focus {
    background-color: var(--color-text-secondary);
  }

  .group-item {
    border-bottom-color: var(--color-text-tertiary);
  }

  .group-item:hover {
    background-color: var(--color-text-primary);
  }

  .group-item:active {
    background-color: var(--color-text-secondary);
  }

  .group-name {
    color: var(--color-surface);
  }

  .unread .group-name {
    color: var(--color-surface);
  }

  .last-message {
    color: var(--color-text-tertiary);
  }

  .last-active {
    color: var(--color-text-tertiary);
  }
} */