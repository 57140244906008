/* Auth.css */

.auth-container {
  max-width: 400px;
  width: 100%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--color-surface);
  border-radius: 12px;
  position: relative;
  transition: all 0.3s ease;
}

.auth-header {
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the title */
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative; /* Allows positioning of the back button within the header */
}

.auth-header-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--color-text-primary);
  text-align: center;
  flex: 1; /* Allows the title to take up available space */
}

.auth-header .back-button {
  position: absolute; /* Positions the back button relative to the header */
  left: 0; /* Aligns the back button to the left */
  background: transparent; /* No background */
  border: none; /* No border */
  padding: 7px; /* Clickable area */
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: var(--color-text-primary); /* Icon color */
  transition: color 0.3s ease, transform 0.2s ease;
}

.auth-header .back-button:hover {
  color: var(--color-primary); /* Change icon color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.auth-header .back-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--color-focus);
}

.auth-header .back-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.auth-header .back-button svg {
  width: 24px; /* Adjust icon size as needed */
  height: 24px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-text-secondary);
}

.auth-input,
.phone-input .PhoneInputInput {
  width: 100%;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  border: 1px solid var(--color-text-tertiary);
  border-radius: 8px;
  background-color: var(--color-background);
  color: var(--color-text-primary);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.auth-input:focus,
.phone-input .PhoneInputInput:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px var(--color-focus);
}

.phone-input {
  display: flex;
  align-items: center;
}

.phone-input .PhoneInputCountry {
  margin-right: 0.5rem;
}

.auth-error {
  color: var(--color-error);
  font-size: 0.875rem;
  margin-top: 1rem;
  text-align: center;
  padding: 0.75rem;
  background-color: var(--color-error-bg);
  border-radius: 8px;
}

.auth-info {
  color: var(--color-success);
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 16px 0;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #ccc;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.button-group {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px; /* Space between buttons */
  margin-top: 16px; /* Space above the button group */
}

.button-group .google-button,
.button-group .toggle-button {
  width: 100%; /* Make buttons full-width */
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-group .google-button svg,
.button-group .toggle-button svg {
  margin-right: 8px; /* Space between icon and text */
  width: 20px; /* Adjust icon size as needed */
  height: 20px;
}

.button-icon {
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* New styles for password reset functionality */
.forgot-password-link {
  font-size: 0.875rem;
  color: var(--color-primary);
  text-align: center;
  text-decoration: none;
  margin-top: 0.5rem;
  transition: color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.forgot-password-link:hover {
  color: var(--color-primary-dark);
  text-decoration: underline;
}

.forgot-password-link:focus {
  outline: none;
  text-decoration: underline;
}

.password-input-wrapper {
  position: relative;
}

.toggle-password-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary);
  transition: color 0.3s ease;
}

.toggle-password-visibility:hover,
.toggle-password-visibility:focus {
  color: var(--color-primary);
  outline: none;
}