/* src/components/PostDetailView.module.css */
.postDetailView {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0 16px 0;
  }
  
  .detailHeader {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-primary);
    cursor: pointer;
    min-height: 40px;
    min-width: 40px;
  }
  
  .backButton:hover {
    color: var(--color-primary);
  }
  
  /* Error state styling */
  .errorState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 16px;
    text-align: center;
    gap: 16px;
  }
  
  .errorState h2 {
    font-weight: var(--font-weight-semibold);
    color: var(--color-text-primary);
    margin: 0;
  }
  
  .errorState p {
    color: var(--color-text-secondary);
    margin: 0 0 16px;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .postDetailView {
      max-width: 680px;
      padding: 0 16px 24px;
    }
    
    .detailHeader {
      padding: 16px;
    }
  }