/* src/components/GroupChatInterface.css */

/* Import base chat interface styles */
@import './ChatInterface.css';

.group-chat-interface {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 127px);
    background-color: var(--color-surface);
    overflow: hidden;
}

.group-chat-interface .message {
    padding: 0.3rem 0.7rem;
    gap: 0.25rem;
    /* Long-press functionality specific to group chat */
    user-select: none; /* Prevent text selection by default */
    transition: transform 0.2s ease, opacity 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;
    transform-origin: center;
    will-change: transform, opacity, background-color, box-shadow;
}

/* Only enable text selection when explicitly toggled */
.group-chat-interface .message.selecting-text {
    user-select: text;
}

/* Press animation styles */
.group-chat-interface .message.pressing {
    transform: scale(0.98);
    opacity: 0.95;
}

.group-chat-interface .message-container.sent .message.pressing {
    background-color: var(--color-primary-dark);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.group-chat-interface .message-container.received .message.pressing {
    background-color: var(--color-background-pressed);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

/* Visual indicator for when a message is being replied to */
.group-chat-interface .message.replying-to {
    animation: pulse-highlight 2s infinite;
}

@keyframes pulse-highlight {
    0%, 100% {
      box-shadow: 0 0 0 0 rgba(var(--color-primary-rgb), 0);
    }
    50% {
      box-shadow: 0 0 0 4px rgba(var(--color-primary-rgb), 0.3);
    }
}

.message-content {
    width: 100%;
}

/* Message timestamp styles */
.message-timestamp {
    font-size: 0.75rem;
    padding-right: 2px;
    align-self: flex-end;
    white-space: nowrap;
}

.message-container.received .message-timestamp {
    color: var(--color-text-tertiary);
}

.message-container.sent .message-timestamp {
    color: rgba(255, 255, 255, 0.8);
}

/* Date separator styles */
.date-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    position: relative;
}

.date-separator-content {
    background-color: var(--color-surface);
    padding: 0.25rem 1rem;
    border-radius: 1rem;
    font-size: 0.75rem;
    color: var(--color-text-secondary);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    z-index: 1;
}

.date-separator::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--color-text-tertiary);
    opacity: 0.2;
    z-index: 0;
}

/* Message reply styles */
.message-reply-reference {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.85em;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    max-width: 90%;
    cursor: pointer;
    transition: background-color 0.2s ease;
    position: relative;
    z-index: 2; /* Ensure this is above other elements */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1); /* Visual feedback for taps on iOS */
    touch-action: manipulation; /* Optimize for touch */
}

.message-reply-reference:active {
    background-color: rgba(0, 0, 0, 0.12); /* Darker background when pressed */
    transform: scale(0.98); /* Slight scale effect when pressed */
}

.message-reply-reference:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.message-container.sent .message-reply-reference {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--color-surface);
}

.message-container.sent .message-reply-reference:hover {
    background-color: rgba(255, 255, 255, 0.15);
}

.message-container.received .message-reply-reference {
    background-color: var(--color-background);
    color: var(--color-text-secondary);
}

.reply-line {
    width: 2px;
    height: 1rem;
    background-color: var(--color-primary);
    border-radius: 1px;
}

.message-container.sent .reply-line {
    background-color: var(--color-surface);
    opacity: 0.6;
}

.message-container.received .reply-line {
    background-color: var(--color-primary);
    opacity: 0.6;
}

.reply-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 200px;
    overflow: hidden;
}

.reply-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-sm);
}

.reply-image-icon {
    flex-shrink: 0;
    opacity: 0.7;
}

/* Image in message styles */
.message-image {
    max-width: 100%;
    border-radius: 8px;
    margin: 4px 0;
    transition: opacity 0.2s ease;
}

.message-image:hover {
    opacity: 0.9;
}

.message-image:active {
    opacity: 0.7;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
    .message-timestamp {
        font-size: 0.7rem;
    }
    
    .date-separator-content {
        font-size: 0.7rem;
        padding: 0.2rem 0.8rem;
    }

    .message-reply-reference {
        font-size: 0.8em;
        padding: 0.4rem;
        max-width: 95%;
    }

    .reply-content {
        max-width: 150px;
    }

    .reply-line {
        height: 0.875rem;
    }    
}

/* Rest of the styles can be shared with ChatInterface.css */