/* src/components/GroupHeader.css */

.group-header {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--color-surface);
  border-bottom: 1px solid #e5e7eb;
}

.group-header-container {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 0.5rem 0 0;
}

.group-header-left {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0; /* Enable text truncation in flex container */
}

.group-header-left .back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  color: var(--color-text-secondary);
  transition: background-color 0.2s;
}

.group-header-left .back-button:hover {
  background-color: var(--color-hover);
}

.group-info {
  display: flex;
  align-items: center;
  min-width: 0; /* Enable text truncation */
  flex: 1;
  padding: 4px;
  margin: -4px;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: left;
}

.group-info:hover {
  background-color: var(--color-hover);
}

/* Add subtle active state for better touch feedback */
.group-info:active {
  background-color: var(--color-hover);
  opacity: 0.8;
}

.group-icon,
.group-icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
}

.group-icon {
  object-fit: cover;
}

.group-icon-placeholder {
  background-color: var(--color-background);
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 600;
}

.group-details {
  margin-left: 0.75rem;
  min-width: 0; /* Enable text truncation */
  flex: 1;
}

.group-name {
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-text-primary);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group-meta {
  display: flex;
  align-items: center;
  margin-top: 2px;
  color: var(--color-text-secondary);
  font-size: 0.875rem;
}

.group-meta span {
  display: flex;
  align-items: center;
  gap: 4px;
}

.group-meta svg {
  margin-right: 4px;
}

.group-header-right {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.bot-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--color-text-secondary);
}

.bot-toggle-button:hover {
  background-color: var(--color-hover);
}

.bot-toggle-button:active {
  background-color: var(--color-hover);
  opacity: 0.8;
}

.bot-icon {
  transition: color 0.2s ease;
}

.bot-icon.enabled {
  color: var(--color-primary-light);
}

.bot-icon.disabled {
  color: var(--color-text-secondary);
}

.bot-icon.disabled {
  color: var(--color-text-secondary);
}

.all-posts-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--color-text-secondary);
}

.all-posts-button:hover {
  background-color: var(--color-hover);
}

.all-posts-button:active {
  background-color: var(--color-hover);
  opacity: 0.8;
}

.posts-icon {
  transition: color 0.2s ease;
  color: var(--color-text-secondary);
}