@import './styles/colors.css';
@import './styles/typography.css';

body {
  margin: 0;
  font-family: var(--font-family-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
  color: var(--color-text-primary);
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  background-color: var(--color-surface);
}

.main-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}
