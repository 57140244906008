/* src/styles/Profile.css */

.profile-container {
  width: 100%;
  max-width: 42rem;
  margin: 0 auto;
}

/* Tabs Section */
.profile-tabs {
  margin-top: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
}

.profile-tab {
  padding: 0.75rem 0;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.profile-tab:hover {
  color: #111827;
}

.profile-tab.active {
  color: var(--color-primary);
}

.profile-tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--color-primary);
}

/* Search Section */
.profile-posts-search {
  padding: 1rem;
}

/* Content Section */
.profile-content {
  padding: 1rem;
}

/* Loading State */
.profile-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #6b7280;
}

/* Error State */
.profile-error {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fee2e2;
  color: #dc2626;
  text-align: center;
}

@media (max-width: 768px) {
  .profile-container {
    padding-bottom: 4rem; /* Space for mobile navigation if needed */
  }

  .profile-tabs {
    padding: 0 0.5rem;
    gap: 0.5rem;
  }

  .profile-tab {
    flex: 1;
    text-align: center;
  }

  .search-container,
  .profile-content {
    padding: 0.75rem;
  }
}