/* src/components/AllPostsInGroup.css */

.all-posts-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.all-posts-modal {
  background-color: var(--color-surface);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  animation: modal-slide-in 0.3s ease-out;
}

.all-posts-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--color-surface);
  border-radius: 12px 12px 0 0;
  z-index: 1;
}

.all-posts-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--color-text);
  margin: 0;
}

.all-posts-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  border: none;
  background: transparent;
  color: var(--color-text-secondary);
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.all-posts-close-button:hover {
  background-color: var(--color-background);
  color: var(--color-text);
}

.all-posts-content {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
}

.all-posts-error {
  text-align: center;
  color: var(--color-error);
  padding: 40px 20px;
}

.all-posts-empty {
  text-align: center;
  color: var(--color-text-secondary);
  padding: 40px 20px;
}

@keyframes modal-slide-in {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .all-posts-overlay {
      padding: 0;
  }

  .all-posts-modal {
      margin: 0;
      height: 100vh;
      max-height: 100vh;
      border-radius: 0;
  }

  .all-posts-header {
      border-radius: 0;
  }
}