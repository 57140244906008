/* src/components/AddMembersModal.module.css */

.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  position: relative;
  background-color: var(--color-surface);
  border-radius: 12px;
  width: 100%;
  max-width: 480px;
  margin: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-border);
}

.closeButton {
  color: var(--color-text-secondary);
  background-color: transparent;
  padding: 8px;
  margin: -8px;
  border: none;
  transition: color 0.2s ease;
}

.closeButton:hover {
  color: var(--color-text);
}

.title {
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  margin: 0;
  padding: 0 32px;
}

.searchContainer {
  padding: 8px 16px;
  border-bottom: 1px solid var(--color-border);
}

.searchWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchIcon {
  position: absolute;
  left: 12px;
  color: var(--color-text-secondary);
  pointer-events: none;
}

.searchInput {
  width: 100%;
  padding: 8px 12px 8px 40px;
  margin-bottom: 0;
  border-radius: 8px;
  border: none;
  background-color: var(--color-background);
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.searchInput:focus {
  outline: none;
  background-color: var(--color-background-hover);
}

.friendsList {
  max-height: 400px;
  overflow-y: auto;
  padding: 8px 0;
}

.noFriendsMessage {
  text-align: center;
  color: var(--color-text-secondary);
  padding: 24px 16px;
  margin: 0;
}

.errorMessage {
  color: var(--color-error);
  text-align: center;
  padding: 8px 16px;
  margin: 0;
  background-color: var(--color-error-background);
}

.loadingOverlay {
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 32px;
  height: 32px;
  border: 3px solid var(--color-background);
  border-top-color: var(--color-primary);
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}