/* src/pages/GroupPage.css */

.group-page {
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;
    background-color: #f9fafb;
    position: relative;
  }
  
  .error-banner {
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fee2e2;
    border: 1px solid #ef4444;
    border-radius: 6px;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .error-banner p {
    margin: 0;
    color: #dc2626;
    font-size: 0.875rem;
  }
  
  .error-close {
    background: none;
    border: none;
    color: #dc2626;
    cursor: pointer;
    font-size: 1.25rem;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .error-close:hover {
    background-color: rgba(220, 38, 38, 0.1);
  }
  
  @media (max-width: 640px) {  
    .error-banner {
      width: calc(100% - 2rem);
      top: 0.5rem;
    }
  }