/* src/components/MessageContextMenu.css */

.message-context-menu {
    position: fixed;
    background-color: var(--color-surface);
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    padding: 8px;
    z-index: 1000;
    min-width: 180px;
    animation: menu-scale-in 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform-origin: top center;
    user-select: none;
    backdrop-filter: blur(12px);
    overflow: hidden;
  }
  
  @keyframes menu-scale-in {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .context-menu-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    border-radius: 12px;
    cursor: pointer;
    color: var(--color-text-primary);
    font-weight: 500;
    font-size: 15px;
    transition: all 0.15s ease-in-out;
  }
  
  .context-menu-item:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  
  .context-menu-item:active {
    background-color: rgba(0, 0, 0, 0.06);
    transform: scale(0.98);
  }
  
  .context-menu-item:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.03);
  }
  
  .context-menu-item svg {
    color: var(--color-primary);
    flex-shrink: 0;
  }
  
  .context-menu-item span {
    flex-grow: 1;
  }
  
  /* Toast notification for copied text */
  .copy-toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 0.9em;
    font-weight: 500;
    z-index: 2000;
    animation: toast-fade 2s ease-in-out forwards;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  @keyframes toast-fade {
    0% { opacity: 0; transform: translate(-50%, 20px); }
    10% { opacity: 1; transform: translate(-50%, 0); }
    90% { opacity: 1; transform: translate(-50%, 0); }
    100% { opacity: 0; transform: translate(-50%, -20px); }
  }
  
  /* Mobile responsive adjustments */
  @media (max-width: 768px) {
    .message-context-menu {
      min-width: 160px;
      border-radius: 14px;
    }
    
    .context-menu-item {
      padding: 10px 14px;
      font-size: 14px;
    }
  }
  
  /* For very small devices */
  @media (max-width: 370px) {
    .message-context-menu {
      min-width: 140px;
    }
    
    .context-menu-item {
      padding: 8px 12px;
      gap: 8px;
    }
  }