.notification-item {
  display: flex;
  align-items: flex-start;
  padding: 14px 16px;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.2s ease;
  cursor: pointer;
  position: relative;
}

.notification-item:hover {
  background-color: #f9f9f9;
}

.notification-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #f5f5f5;
  margin-right: 12px;
  flex-shrink: 0;
  margin-top: 2px;
}

.notification-icon {
  padding: 0;
  background-color: transparent;
}

.notification-icon.like {
  color: #e31b23;
}

.notification-icon.share {
  color: #4a90e2;
}

.notification-icon.comment {
  color: #f5a623;
  margin-bottom: 0; /* Cancel out conflicting margin */
  padding: 0; /* Cancel out conflicting padding */
}

.notification-icon.follow {
  color: #7ed321;
}

.notification-icon.reply {
  color: #9b59b6;
}

.notification-icon.mention {
  color: #3498db;
}

.notification-content {
  flex: 1;
  min-width: 0; /* Helps with text overflow in flex items */
}

.notification-text {
  margin: 0 0 4px 0;
  font-size: 14px;
  color: #333;
  line-height: 1.4;
  word-break: break-word;
}

.notification-time {
  font-size: 12px;
  color: #999;
  display: block;
}