/* src/components/ImagePreviewModal.css */

.image-preview-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    animation: fadeIn 0.2s ease;
  }
  
  .image-preview-content {
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    color: var(--color-surface);
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    max-height: 80vh;
    max-width: 100%;
  }
  
  .image-preview {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 4px;
    animation: zoomIn 0.3s ease;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    /* Enable long-press context menu on mobile */
    -webkit-touch-callout: default;
    -webkit-user-select: auto;
    user-select: auto;
    pointer-events: auto;
  }
  
  .image-preview-content .close-button {
    position: absolute;
    top: -40px;
    right: 0;
    background: transparent;
    border: none;
    color: white;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
  }
  
  .image-preview-content .close-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .download-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--color-primary);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.2s ease;
    margin-bottom: 8px;
  }
  
  .download-button:hover {
    background-color: var(--color-primary-dark);
  }
  
  .mobile-hint {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 8px;
    text-align: center;
    display: none;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes zoomIn {
    from { transform: scale(0.9); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .image-preview-content .close-button {
      top: -35px;
      right: 0;
    }
    
    .mobile-hint {
      display: block;
      padding: 0 16px;
    }
    
    .download-button {
      padding: 10px 16px;
    }
  }