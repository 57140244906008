/* src/components/ChatPostList.css */

.chat-post-list {
  margin-bottom: 0.3rem;
  background-color: var(--color-background);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 97%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .chat-post-list {
    padding: 0.55rem 0.35rem 0rem 0.35rem;
  }
}