.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    flex: 1;
    padding: 10px 20px 60px;
  }
  
  @media (max-width: 768px) {
    .main-content {
      padding: 5px 10px 74px;
    }
  }

@media (min-width: 768px) {
  .main-content {
    padding-bottom: 75px; /* Increased padding for larger screens */
  }
}