/* src/components/ProfileHeader/ProfileHeader.css */

.profile-header {
    width: 100%;
    max-width: 42rem;
    margin: 0 auto;
    padding: 1rem;
    position: relative;
  }
  
  .profile-header-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .profile-header-main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .profile-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    width: 100%;
  }
  
  .profile-picture-container {
    position: relative;
  }
  
  .profile-picture {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: var(--color-background);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-picture img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-picture-placeholder {
    width: 4rem;
    height: 4rem;
    color: var(--color-text-tertiary);
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .profile-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    color: var(--color-text-primary);
  }
  
  .profile-username {
    font-size: 0.875rem;
    color: var(--color-text-secondary);
    margin: 0;
  }
  
  .profile-bio {
    font-size: 0.875rem;
    color: var(--color-text-secondary);
    max-width: 28rem;
    margin: 0;
  }
  
  .profile-settings-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem;
    border-radius: 9999px;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.2s;
    color: var(--color-text-primary);
  }
  
  .profile-settings-button:hover {
    background-color: var(--color-hover);
  }
  
  .profile-settings-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .profile-action-button {
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 0.875rem;
  }
  
  .profile-action-button-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .edit-button {
    background-color: var(--color-background);
    color: var(--color-text-primary);
  }
  
  .edit-button:hover {
    background-color: var(--color-hover);
  }
  
  .connect-button {
    background-color: var(--color-focus);
    color: var(--color-primary);
  }
  
  .connect-button:hover {
    background-color: var(--color-focus);
    opacity: 0.9;
  }
  
  .requested-button {
    background-color: var(--color-background);
    color: var(--color-text-secondary);
    cursor: not-allowed;
  }
  
  .connected-button {
    background-color: var(--color-success);
    color: var(--color-surface);
    cursor: not-allowed;
    opacity: 0.9;
  }
  
  @media (min-width: 768px) {
    .profile-info-container {
      flex-direction: row;
      text-align: left;
      align-items: flex-start;
    }
  }