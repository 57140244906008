/* src/styles/GroupChatPage.css */

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.error-message {
  margin-bottom: 20px;
  color: var(--color-error);
  text-align: center;
}

.error-message .back-button {
  background: transparent;
  color: var(--color-text-secondary);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.error-message .back-button:hover {
  background-color: var(--color-hover);
}