/* src/components/FriendSkeleton/FriendSkeleton.css */
.friend-skeleton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: var(--color-surface);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    margin-bottom: 12px;
  }
  
  .friend-skeleton-left {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
  
  .friend-skeleton-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--color-skeleton-base);
    flex-shrink: 0;
  }
  
  .friend-skeleton-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .friend-skeleton-name {
    height: 20px;
    width: 150px;
    background: var(--color-skeleton-base);
    border-radius: 4px;
  }
  
  .friend-skeleton-username {
    height: 16px;
    width: 100px;
    background: var(--color-skeleton-base);
    border-radius: 4px;
  }
  
  /* Skeleton animation */
  .friend-skeleton-avatar,
  .friend-skeleton-name,
  .friend-skeleton-username {
    animation: skeleton-loading 1.5s infinite;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: var(--color-skeleton-base);
    }
    50% {
      background-color: var(--color-skeleton-highlight);
    }
    100% {
      background-color: var(--color-skeleton-base);
    }
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .friend-skeleton-avatar {
      width: 40px;
      height: 40px;
    }
  
    .friend-skeleton-name {
      height: 18px;
      width: 120px;
    }
  
    .friend-skeleton-username {
      height: 14px;
      width: 80px;
    }
  }