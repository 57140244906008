/* src/components/FriendList/FriendList.css */
.friend-list {
    margin-top: 30px;
    background-color: var(--color-surface);
    border-radius: 8px;
    padding: 20px;
    border: 1px solid var(--color-border);
  }
  
  .friend-list h3 {
    margin: 0 0 20px 0;
    font-size: 20px;
    font-weight: 500;
    color: var(--color-text-primary);
  }
  
  .friend-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .friend-list-empty {
    text-align: center;
    padding: 30px;
    color: var(--color-text-secondary);
    background-color: var(--color-surface-variant);
    border-radius: 8px;
    margin: 0;
    font-size: 15px;
  }
  
  @media (max-width: 768px) {
    .friend-list {
      padding: 15px;
      margin-top: 20px;
    }
  
    .friend-list h3 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }