/* src/components/GroupSettings.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.settingsModal {
  width: 100%;
  height: 100%;
  background: var(--color-surface);
  position: relative;
  overflow: hidden;
  animation: slideInRight 0.3s ease-out;
}

@media (min-width: 768px) {
  .settingsModal {
    width: 480px;
    height: auto;
    max-height: 90vh;
    border-radius: 12px;
  }
}

.settingsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--color-text-tertiary);
  background: var(--color-surface);
}

.settingsHeader h2 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  flex: 1;
  text-align: center;
  color: var(--color-text-primary);
}

.closeButton,
.saveButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--color-text-primary);
}

.saveButton {
  color: var(--color-primary-light);
  font-weight: 600;
  font-size: 14px;
}

.saveButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.settingsContent {
  height: calc(100% - 56px);
  overflow-y: auto;
}

.groupInfo {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--color-text-tertiary);
}

.groupIconUpload {
  position: relative;
  margin-bottom: 16px;
}

.iconLabel {
  display: block;
  cursor: pointer;
  position: relative;
}

.iconLabel.disabled {
  cursor: default;
  opacity: 0.7;
}

.iconPreview,
.iconPlaceholder {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  object-fit: cover;
}

.iconPlaceholder {
  background: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-secondary);
}

.cameraBadge {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--color-primary-light);
  border-radius: 50%;
  color: var(--color-surface);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.groupNameInput {
  background: transparent;
  border: none;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  color: var(--color-text-primary);
}

.groupNameInput:focus {
  outline: none;
}

.groupNameInput:disabled {
  color: var(--color-text-primary);
  opacity: 0.7;
}

.settingsMenu {
  padding: 8px 0;
}

.menuItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-primary);
  transition: background-color 0.2s;
}

.menuItem:hover {
  background-color: var(--color-hover);
}

.menuItem.danger {
  color: var(--color-error);
}

.menuItemContent {
  display: flex;
  align-items: center;
  gap: 12px;
}

.menuItemContent span {
  font-size: 16px;
}

.menuItemRight {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-text-secondary);
}

.memberCount {
  font-size: 14px;
}

/* Toggle Switch */
.toggleSwitch {
  position: relative;
  width: 44px;
  height: 24px;
}

.toggleInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-text-tertiary);
  transition: 0.4s;
  border-radius: 24px;
}

.toggleSlider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: var(--color-surface);
  transition: 0.4s;
  border-radius: 50%;
}

.toggleInput:checked + .toggleSlider {
  background-color: var(--color-primary-light);
}

.toggleInput:checked + .toggleSlider:before {
  transform: translateX(20px);
}

/* Members View */
.membersView {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-surface);
  z-index: 1;
  animation: slideInRight 0.3s ease-out;
}

@media (min-width: 768px) {
  .membersView {
    position: relative;
    height: auto;
    max-height: 90vh;
  }
}

.membersViewHeader {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--color-text-tertiary);
}

.backButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  transform: rotate(180deg);
  color: var(--color-text-primary);
}

.membersViewHeader h2 {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: var(--color-text-primary);
}

.addMemberButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--color-primary-light);
}

.membersList {
  padding: 8px 16px;
  overflow-y: auto;
  height: calc(100% - 57px);
}

.memberItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid var(--color-text-tertiary);
}

.memberItem:last-child {
  border-bottom: none;
}

.memberInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.memberAvatar,
.memberAvatarPlaceholder {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}

.memberAvatarPlaceholder {
  background: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--color-text-secondary);
}

.memberName {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--color-text-primary);
}

.adminBadge {
  color: var(--color-text-secondary);
}

.removeMemberButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: var(--color-error);
}

.errorMessage {
  padding: 16px;
  color: var(--color-error);
  font-size: 14px;
  text-align: center;
  background: var(--color-hover);
}

.hidden {
  display: none;
}

/* Animations */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@media (prefers-reduced-motion: reduce) {
  .settingsModal,
  .membersView {
    animation: none;
  }
}