.affin-button {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-medium);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  user-select: none;
  letter-spacing: 0.01em;
  gap: 8px;
}

.affin-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--color-focus);
}

.affin-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  transform: none !important;
}

/* Loading State */
.affin-button.loading {
  cursor: wait;
}

.affin-button.loading .button-text {
  opacity: 0.7;
}

.affin-button .spinner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
}

.affin-button.secondary .spinner,
.affin-button.tertiary .spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: var(--color-primary);
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Variants */
.affin-button.primary {
  background-color: var(--color-primary);
  color: white;
  box-shadow: 0 2px 4px rgba(255, 56, 92, 0.2);
}

.affin-button.primary:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(255, 56, 92, 0.25);
}

.affin-button.primary:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 1px 2px rgba(255, 56, 92, 0.2);
}

.affin-button.secondary {
  background-color: var(--color-surface);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.affin-button.secondary:hover:not(:disabled) {
  background-color: var(--color-hover);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.affin-button.secondary:active:not(:disabled) {
  transform: translateY(0);
}

.affin-button.tertiary {
  background-color: transparent;
  color: var(--color-primary);
}

.affin-button.tertiary:hover:not(:disabled) {
  background-color: var(--color-hover);
}

.affin-button.tertiary:active:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.08);
}

/* New Gradient Variant */
.affin-button.gradient {
  background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-accent-2) 100%);
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.affin-button.gradient:hover:not(:disabled) {
  background: linear-gradient(135deg, var(--color-primary-dark) 0%, var(--color-accent-2) 90%);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Sizes */
.affin-button.small {
  font-size: var(--font-size-sm);
  padding: 8px 16px;
  height: 36px;
  border-radius: 8px;
}

.affin-button.medium {
  font-size: var(--font-size-md);
  padding: 10px 18px;
  height: 44px;
  border-radius: 10px;
}

.affin-button.large {
  font-size: var(--font-size-md);
  padding: 14px 24px;
  height: 52px;
  border-radius: 12px;
  font-weight: var(--font-weight-semibold);
}

.affin-button.pill {
  border-radius: 9999px;
}

.affin-button.full-width {
  width: 100%;
}

/* Ripple effect */
.affin-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%, -50%);
  transform-origin: 50% 50%;
}

.affin-button:active:not(:disabled)::after {
  animation: ripple 0.6s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(20, 20);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .affin-button {
    height: 44px; /* Ensure buttons are easily tappable on mobile */
  }
}