/* src/pages/Friends.css */
.friends-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

@media (max-width: 768px) {
  .friends-page {
    padding: 15px;
  }
}