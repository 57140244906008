/* src/components/ReplyIndicator.css */

.reply-indicator {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 8px 16px;
    background-color: var(--color-background);
    border-radius: 24px;
    gap: 12px;
    animation: reply-slide-in 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.reply-indicator-info {
    flex: 1;
    overflow: hidden;
}

.reply-to-name {
    font-weight: 600;
    font-size: 0.85em;
    color: var(--color-primary);
}

.reply-preview {
    font-size: 0.8em;
    color: var(--color-text-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.cancel-reply {
    background: none;
    border: none;
    color: var(--color-text-tertiary);
    cursor: pointer;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.15s ease;
}

.cancel-reply:hover {
    color: var(--color-text-primary);
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(1.1);
}

.cancel-reply:active {
    transform: scale(0.95);
}

.cancel-reply:focus {
    outline: none;
    color: var(--color-text-primary);
    background-color: rgba(0, 0, 0, 0.05);
}

@keyframes reply-slide-in {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
    .reply-indicator {
        padding: 6px 12px;
        border-radius: 20px;
    }
    
    .reply-preview {
        max-width: 140px;
    }
}