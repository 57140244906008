/* src/components/DraftItem.css */

.draft-item {
    background-color: var(--color-surface);
    border-radius: 1rem;
    margin: 0.5rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    max-width: 100%;
    width: 90%;
  }
  
  .draft-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .draft-header {
    background-color: var(--color-background);
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .draft-title {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .draft-title h3 {
    font-size: var(--font-size-lg);
    color: var(--color-text-primary);
    margin: 0;
    line-height: 1.4;
  }
  
  .draft-timestamp {
    font-size: var(--font-size-sm);
    color: var(--color-text-secondary);
  }
  
  .expand-button {
    background: none;
    border: none;
    padding: 0.5rem;
    color: var(--color-text-secondary);
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .expand-button:hover {
    color: var(--color-text-primary);
  }
  
  .draft-details {
    padding: 1rem;
  }
  
  .draft-content {
    color: var(--color-text-secondary);
    font-size: var(--font-size-md);
    line-height: 1.6;
  }
  
  .draft-content h1,
  .draft-content h2,
  .draft-content h3,
  .draft-content h4,
  .draft-content h5,
  .draft-content h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: var(--color-text-primary);
  }
  
  .draft-content ul,
  .draft-content ol {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
  
  .draft-content pre,
  .draft-content code {
    background-color: var(--color-background);
    border-radius: 4px;
    padding: 0.2rem 0.4rem;
    font-family: monospace;
  }
  
  .draft-content blockquote {
    border-left: 4px solid var(--color-text-secondary);
    padding-left: 1rem;
    margin-left: 0;
    font-style: italic;
  }
  
  .draft-edit-form {
    display: flex;
    flex-direction: column;
  }
  
  .draft-input,
  .draft-textarea,
  .draft-select {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid var(--color-text-tertiary);
    border-radius: 8px;
    font-size: var(--font-size-md);
    background-color: var(--color-surface);
    color: var(--color-text-primary);
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .draft-input:focus,
  .draft-textarea:focus,
  .draft-select:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px var(--color-focus);
  }
  
  .draft-textarea {
    min-height: 100px;
    overflow-y: hidden;
    resize: none;
    transition: height 0.1s ease;
  }
  
  .draft-link {
    color: var(--color-primary);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    margin-top: 0.5rem;
    display: inline-block;
    word-break: break-word;
  }
  
  .draft-link:hover {
    color: var(--color-primary-dark);
    text-decoration: underline;
  }
  
  .draft-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  
  .tag {
    background-color: var(--color-background);
    color: var(--color-text-secondary);
    border-radius: 16px;
    padding: 0.25rem 0.75rem;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
  }
  
  .draft-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid var(--color-text-tertiary);
  }
  
  .draft-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .error-message {
    color: var(--color-error);
    background-color: rgba(234, 67, 53, 0.1);
    border: 1px solid var(--color-error);
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    font-size: var(--font-size-sm);
  }