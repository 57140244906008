:root {
    /* Primary Colors */
    --color-primary: #FF385C; /* Airbnb-inspired red */
    --color-primary-light: #FF5A5F;
    --color-primary-dark: #D70466;
  
    /* Neutral Colors */
    --color-background: #F7F7F7;
    --color-background-pressed: #EBEBEB; /* Slightly darker bg for press effect */
    --color-surface: #FFFFFF;
    --color-on-surface: #222222;
  
    /* Text Colors */
    --color-text-primary: #484848;
    --color-text-secondary: #767676;
    --color-text-tertiary: #BBBBBB;
  
    /* Accent Colors */
    --color-accent-1: #00A699; /* Teal */
    --color-accent-2: #FC642D; /* Orange */
    --color-accent-3: #4A4A4A; /* Dark Grey */
  
    /* Feedback Colors */
    --color-success: #34A853;
    --color-error: #EA4335;
    --color-warning: #FBBC05;
    --color-info: #4285F4;
  
    /* Interaction Colors */
    --color-hover: rgba(0, 0, 0, 0.04);
    --color-focus: rgba(255, 56, 92, 0.24);

    /* Skeleton Colors */
    --color-skeleton-base: #eee;
    --color-skeleton-highlight: #f5f5f5;
    
    /* RGB values for animation effects */
    --color-primary-rgb: 255, 56, 92; /* #FF385C */
    --color-success-rgb: 52, 168, 83; /* #34A853 */
    --color-text-tertiary-rgb: 187, 187, 187; /* #BBBBBB */
  }