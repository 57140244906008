/* Animations */
@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes fade {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* Profile Header */
.profile-skeleton-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
}

.profile-skeleton-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.profile-skeleton-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  background-color: var(--color-background, #f6f7f8);
  animation: fade 1.5s ease-in-out infinite;
}

.profile-skeleton-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.profile-skeleton-name {
  height: 1.75rem;
  width: 120px;
  border-radius: 4px;
  background-color: var(--color-background, #f6f7f8);
  animation: fade 1.5s ease-in-out infinite;
}

.profile-skeleton-username {
  height: 1.25rem;
  width: 100px;
  border-radius: 4px;
  background-color: var(--color-background, #f6f7f8);
  animation: fade 1.5s ease-in-out infinite;
}

.profile-skeleton-bio {
  height: 1.25rem;
  width: 200px;
  border-radius: 4px;
  background-color: var(--color-background, #f6f7f8);
  animation: fade 1.5s ease-in-out infinite;
}

.profile-skeleton-button {
  height: 2.5rem;
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
  border-radius: 0.75rem;
  background-color: var(--color-background, #f6f7f8);
  animation: fade 1.5s ease-in-out infinite;
}

/* Tabs */
.profile-skeleton-tabs {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e5e7eb;
  margin-top: 1rem;
}

.profile-skeleton-tab {
  height: 2rem;
  flex: 1;
  border-radius: 4px;
  background-color: var(--color-background, #f6f7f8);
  animation: fade 1.5s ease-in-out infinite;
}

/* Posts */
.profile-skeleton-content {
  padding: 1rem;
}

.profile-skeleton-post {
  background: var(--color-background, #ffffff);
  border-radius: 0.75rem;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.profile-skeleton-post-header {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.profile-skeleton-post-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  background: linear-gradient(
    to right,
    var(--color-background, #f6f7f8) 8%,
    #edeef1 18%,
    var(--color-background, #f6f7f8) 33%
  );
  background-size: 800px 100%;
  animation: shimmer 1.5s infinite linear;
}

.profile-skeleton-post-meta {
  flex: 1;
}

.profile-skeleton-post-title {
  height: 1.25rem;
  width: 180px;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  background: linear-gradient(
    to right,
    var(--color-background, #f6f7f8) 8%,
    #edeef1 18%,
    var(--color-background, #f6f7f8) 33%
  );
  background-size: 800px 100%;
  animation: shimmer 1.5s infinite linear;
}

.profile-skeleton-post-date {
  height: 1rem;
  width: 120px;
  border-radius: 4px;
  background: linear-gradient(
    to right,
    var(--color-background, #f6f7f8) 8%,
    #edeef1 18%,
    var(--color-background, #f6f7f8) 33%
  );
  background-size: 800px 100%;
  animation: shimmer 1.5s infinite linear;
}

.profile-skeleton-post-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.profile-skeleton-post-text {
  height: 1rem;
  width: 100%;
  border-radius: 4px;
  background: linear-gradient(
    to right,
    var(--color-background, #f6f7f8) 8%,
    #edeef1 18%,
    var(--color-background, #f6f7f8) 33%
  );
  background-size: 800px 100%;
  animation: shimmer 1.5s infinite linear;
}

.profile-skeleton-post-text.short {
  width: 70%;
}