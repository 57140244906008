/* src/components/ChatInterface.css */

.chat-interface {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 129px);
  background-color: var(--color-surface);
  overflow: hidden;
}

.message-list {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative; /* Added for absolute positioning of indicators */
}

/* Loading indicator for pagination */
.loading-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: linear-gradient(180deg, var(--color-background) 0%, transparent 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 60px;
}

.loading-more .spinner {
  width: 24px;
  height: 24px;
  border: 3px solid var(--color-background);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

/* Scroll indicator */
.scroll-indicator {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  background-color: var(--color-surface);
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 0.85em;
  color: var(--color-text-secondary);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 2;
}

.scroll-indicator.visible {
  opacity: 1;
}

.message-container {
  margin-bottom: 0.5rem;
  position: relative;
}

.message-container.received {
  padding-left: 50px;
}

.message-container.sent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.message-container.typing .message {
  background-color: var(--color-background);
  color: var(--color-text-secondary);
}

.avatar-container {
  display: flex;
  align-items: center;
  margin-bottom: center;
  position: absolute;
  left: 0;
  top: 0;
}

.sender-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.sender-profile-picture:hover {
  transform: scale(1.05);
}

.sender-display-name {
  font-size: 0.85em;
  color: var(--color-text-secondary);
  margin-bottom: 4px;
}

.message {
  display: flex;
  flex-direction: column;
  padding: 0.7rem 1rem;
  border-radius: 1rem;
  max-width: 80%;
  font-size: var(--font-size-md);
  line-height: var(--line-height-normal);
  position: relative;
}

.message-container.sent .message {
  background-color: var(--color-primary-light);
  color: var(--color-surface);
  border-bottom-right-radius: 4px;
  max-width: 80%;
}

.message-container.received .message {
  background-color: var(--color-background);
  color: var(--color-text-primary);
  border-top-left-radius: 4px;
  max-width: 90%;
}

.message p {
  margin-bottom: 0px;
}

.message h1, .message h2, .message h3, .message h4, .message h5, .message h6 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.message ul, .message ol {
  margin-left: 20px;
  margin-bottom: 10px;
}

.message ul {
  list-style-type: disc;
}

.message ol {
  list-style-type: decimal;
}

.message ul ul {
  list-style-type: circle;
}

.message ul ul ul {
  list-style-type: square;
}

.message code {
  background-color: var(--color-background);
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
}

.message pre {
  background-color: var(--color-background);
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

.message blockquote {
  border-left: 4px solid var(--color-text-tertiary);
  margin: 0;
  padding-left: 16px;
  color: var(--color-text-secondary);
}

/* Typing indicator styles */
.typing-indicator {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  margin: 0 1px;
  background-color: var(--color-text-tertiary);
  border-radius: 50%;
  display: inline-block;
  animation: typing-pulse 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: 0s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing-pulse {
  0%, 60%, 100% {
    transform: translateY(0) scale(1);
    opacity: 0.4;
  }
  30% {
    transform: translateY(-4px) scale(1.2);
    opacity: 0.8;
  }
}

/* Refined progress tracker styles */
.progress-tracker {
  margin: 8px 0;
  width: 100%;
  padding: 4px 0;
}

/* Status indicator pill */
.status-indicator {
  padding: 6px 12px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
}

.status-indicator .status-dot {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50%;
}

.status-indicator .status-dot.thinking {
  background-color: var(--color-primary);
  animation: status-dot-pulse 1.5s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes status-dot-pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

.status-indicator .status-dot.processing {
  background-color: var(--color-warning);
  animation: pulse 1.5s infinite;
}

.status-indicator .status-dot.error {
  background-color: var(--color-error);
}

.status-indicator .status-dot.complete {
  background-color: var(--color-success);
}

/* Integrated progress bar with markers */
.progress-bar-container {
  width: 97%;
  height: 20px; /* Taller to balance larger circles */
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: visible; /* Allow markers to exceed container */
  position: relative;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 3px; /* Reduced since we removed the text labels */
  margin-left: auto;
  margin-right: auto;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, var(--color-primary-light) 0%, var(--color-primary) 100%);
  transition: width 0.8s cubic-bezier(0.22, 1, 0.36, 1);
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* Enhanced active marker animation */
@keyframes marker-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 0 0 0 rgba(var(--color-primary-rgb), 0.7);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.15);
    box-shadow: 0 0 0 6px rgba(var(--color-primary-rgb), 0.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 0 0 0 rgba(var(--color-primary-rgb), 0);
  }
}

@keyframes ring-expand {
  0% {
    width: 18px;
    height: 18px;
    border-width: 2px;
    opacity: 0.8;
  }
  100% {
    width: 32px;
    height: 32px;
    border-width: 1px;
    opacity: 0;
  }
}

/* Stage markers integrated into progress bar */
.progress-stage-markers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 10px;
}

.progress-stage-marker {
  position: relative;
  width: 0;
  height: 100%;
}

/* Base marker style */
.progress-stage-marker::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 18px; /* Larger circles since we removed text */
  height: 18px;
  background-color: var(--color-text-tertiary);
  border: 2px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  transition: all 0.3s ease;
}

/* Completed marker style */
.progress-stage-marker.completed::before {
  background-color: var(--color-success);
  transition: background-color 0.4s ease;
}

/* Checkmark for completed markers that are not active */
.progress-stage-marker.completed:not(.active)::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
  font-weight: bold;
  z-index: 4;
}

/* Active marker enhancement */
.progress-stage-marker.active::before {
  background-color: var(--color-primary);
  animation: marker-pulse 2s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: 0 0 0 4px rgba(var(--color-primary-rgb), 0.2);
  z-index: 5;
}

/* Active marker expanding ring effect */
.progress-stage-marker.active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  animation: ring-expand 2s infinite ease-out;
}

/* Incomplete marker style */
.progress-stage-marker:not(.completed):not(.active)::before {
  background-color: rgba(var(--color-text-tertiary-rgb), 0.5);
  border: 2px solid rgba(255, 255, 255, 0.9);
}

/* Partial response preview */
.partial-response {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px dashed var(--color-text-tertiary);
  color: var(--color-text-secondary);
  font-style: italic;
  font-size: 0.9em;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .progress-tracker {
    margin: 6px 0;
  }
  
  .progress-bar-container {
    height: 18px;
  }
  
  .progress-stage-marker::before {
    width: 16px;
    height: 16px;
  }
  
  .progress-stage-marker.active::after {
    animation: ring-expand-mobile 2s infinite ease-out;
  }
  
  @keyframes ring-expand-mobile {
    0% {
      width: 16px;
      height: 16px;
      opacity: 0.8;
    }
    100% {
      width: 28px;
      height: 28px;
      opacity: 0;
    }
  }
  
  .status-indicator {
    padding: 5px 10px;
  }
}

/* Ultra small screens */
@media (max-width: 350px) {
  .progress-bar-container {
    margin-bottom: 4px;
  }
  
  .progress-stage-marker::before {
    width: 14px;
    height: 14px;
    border-width: 1px;
  }
  
  .progress-stage-marker.active::before {
    box-shadow: 0 0 0 3px var(--color-focus);
  }
}

.update-progress {
  margin-top: 0.5rem;
  font-size: 0.9em;
  color: var(--color-text-secondary);
}

.message-input-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.uploaded-images-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.uploaded-image-container {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-image-button {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: var(--color-surface);
  color: var(--color-text-primary);
  border: none;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.remove-image-button:hover {
  background-color: var(--color-primary-light);
  color: var(--color-surface);
}

.remove-image-button svg {
  width: 16px;
  height: 16px;
}

.message-input {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.message-input textarea {
  width: 100%;
  margin-bottom: 0;
  padding: 12px 45px 12px 40px;
  border: 1px solid var(--color-text-tertiary);
  border-radius: 24px;
  font-size: var(--font-size-md);
  color: var(--color-text-primary);
  resize: none;
  overflow-y: auto;
  min-height: 48px;
  height: auto;
  max-height: 300px;
}

.message-input textarea:focus {
  outline: none;
  border-color: var(--color-primary);
}

.image-upload-button {
  position: absolute;
  left: 13px;
  bottom: -3px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--color-text-secondary);
  cursor: pointer;
  padding: 0;
}

.image-upload-button:hover {
  color: var(--color-primary);
}

.image-upload-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.image-upload-button:disabled:hover {
  color: var(--color-text-secondary);
  opacity: 0.5;
}

.message-input .send-button {
  position: absolute;
  right: 5px;
  bottom: 24px;
  transform: translateY(50%);
  padding: 0.5rem;
  height: 38px;
  width: 38px;
  background-color: var(--color-primary);
  color: var(--color-surface);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease, opacity 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
}

.message-input .send-button.visible {
  opacity: 1;
  pointer-events: auto;
}

.message-input .send-button:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.message-input .send-button:disabled {
  background-color: var(--color-text-tertiary);
  cursor: not-allowed;
}

.message-input .send-button.loading {
  background-color: var(--color-primary-light);
}

.message-input .send-button .loader {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.message-input .send-button svg {
  vertical-align: middle;
  margin: 0;
}

.message-image {
  max-width: 100%;
  border-radius: 8px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.error-message {
  color: var(--color-error);
  background-color: var(--color-error-light);
  border: 1px solid var(--color-error);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

/* Responsive styles for message containers */
@media (max-width: 768px) {
  .message-container.received {
    padding-left: 39px;
  }
  
  .sender-profile-picture {
    width: 32px;
    height: 32px;  
  }

  .message {
    max-width: 90%;
  }
  
  .scroll-indicator {
    font-size: 0.8em;
    padding: 6px 12px;
  }

  .sender-display-name {
    font-size: 0.8em;
  }
}